<template>
    <v-card
        style="background: white; font-family: Montserrat; border: 1px solid gray; border-radius: 10px !important;"
        class="elevation-0 mt-8"
        :height="cardheight"
        width="344"
        rounded="10"
    >
        <v-img :src="chat_img" width="50px" class="ml-7 mt-8"></v-img>
        <v-card-text>
            <div style="text-align: left; font: normal normal 800 20px/25px Montserrat; letter-spacing: 0px; color: #46C3B2; opacity: 1;">Connect with an Expert</div>
            <v-card-actions
                @click="show = !show"
                class="mt-8"
            >
                <v-btn
                color="q-secondary lighten-2"
                text
                class="text-subtitle-2"
                >
                    <span style="text-align: left; font: normal normal bold 17px/22px Montserrat; letter-spacing: 0px; color: #005952; opacity: 1;">Benefits of Working with <br> an Agent</span>
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                color="q-secondary lighten-2"
                icon
                >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-card-actions>

            <v-expand-transition>
                <div v-show="show">
                    <v-card-text>
                        <v-list flat>
                            <v-list-item
                            class="mt-n5"
                                v-for="(msg, i) in benefits"
                                :key="i"
                            >
                                <v-list-item-icon class="mr-0">
                                    <v-icon color="q-primary">mdi-circle-small</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="text-caption  text-wrap" style="color: #005851; font-family: Montserrat !important" v-html="msg"></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </div>
            </v-expand-transition>

            <div class="mb-n7" v-if="site == 'QUILITY'">
                <v-btn
                    color="q-secondary"
                    @click="togglechat"
                    class="get-started px-3 my-2"
                    :disabled="fetchingQuote"
                    depressed
                    block
                >
                    Chat Now
                </v-btn>
                <v-btn
                    color="q-secondary"
                    @click="trackClick"
                    class="schedule-call px-3 my-2"
                    :disabled="fetchingQuote"
                    depressed
                    block
                    outlined
                >
                    Schedule a call
                </v-btn>
            </div>
            <div v-else>
                <v-btn
                    color="q-secondary"
                    depressed
                    class="get-started px-3 my-2"
                    block
                    :disabled="fetchingQuote"
                    @click="trackClick"
                >
                    {{ buttonText }}
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: 'MeetAnAgentCard',
    mixins: [quilityMixin],
    data: () => ({
        show: false,
        chat_img: null
    }),
    mounted(){
        this.show = !this.isMobile
        this.chat_img = this.getImgUrl(this.chat_card_info.logo)
    },
    computed: {
        cardheight() {
            return this.show ? '480' : '323'
        },
        buttonText() {
            if(this.site == 'OC'){
                return this.mma_cta_configs['OC-Button-Text'] ?? 'Chat Now'
            }

            return this.mma_cta_configs['Button-Text'] ?? 'Chat Now'
        },
        topHeader() {
            return this.mma_cta_configs['Top-Header'] ?? 'Meet a Licensed Agent'
        },
        topText() {
            return this.mma_cta_configs['Top-Text'] ?? 'Discuss with a licensed agent who can help you find your best-fit plan'
        },
        benefits() {
            let b = []
            let i  = 1

            /*eslint no-constant-condition: ["error", { "checkLoops": false }]*/
            while(true) {
                const b_key = `Benefits-${i}`
                const b_value = this.mma_cta_configs[b_key]

                if(this.isset(b_value)) {
                    b.push(b_value)
                    i += 1
                } else {
                    break
                }
            }

            return b
        }
    },
    methods: {
        async trackClick() {
            // only tracking for corp dtc at the moment
            if(this.site === 'QUILITY'){
                this.$amplitude_client.track('Call', this.amp_event_data)
            }

            let client = await this.$split_client.latest

            client.track('anonymous', 'click.meet-a-agent.cta')
            this.FindAdvisor()
        },
        async togglechat() {
            // only tracking for corp dtc at the moment
            if(this.site === 'QUILITY'){
                this.$amplitude_client.track('Chat_Now', this.amp_event_data)
            }

            let client = await this.$split_client.latest

            client.track('anonymous', 'click.meet-a-agent.cta')
            this.$store.commit('updateChat', !this.$store.state.showChat)
            this.$emit('showChat', true)
        }
    }
}
</script>

<style lang="scss" scoped>
$text--secondary: #9B9B9B;

.amount {
    font-size: 1.8rem;
    font-weight: 800;
    letter-spacing: 0.32px;
}
.amount-per-month {
    font-size: 0.75rem;
    font-weight: normal;
}


.premium-note {
    color: $text--secondary;
    font-size: 0.5rem;
    text-align: left;
}

.get-started {
    color: #fff !important;
    font-family: Montserrat, SemiBold;
    font-weight: 600;
    font-size: 0.8rem;

    @media only screen and (max-width: 486px) {
        & {
            font-size: 0.7rem !important;
            img {
                width: 12px;
            }
        }
    }
}
.schedule-call {
    color: #005851 !important;
    font-family: Montserrat, SemiBold;
    font-weight: 600;
    font-size: 0.8rem;

    @media only screen and (max-width: 486px) {
        & {
            font-size: 0.7rem !important;
            img {
                width: 12px;
            }
        }
    }
}

.cta-tertiary {
    text-decoration: underline;
    cursor: pointer;
}
</style>