<template class="q-main-navbar">
    <v-app-bar fixed color="white" height="100" elevation="0">
        <v-container v-if="isMobile" class="container" fluid id="q-dt-nav-container">
            <v-row class="pt-9" no-gutters>
                <v-col>
                    <a href="https://quility.com/">
                        <v-img
                                alt="Quility Logo"
                                :src="require('../assets/Quility-Logo-Text-Only-Forest-Green-RGB.svg')"
                                max-height="30"
                                contain
                                position="center"
                        />
                    </a>
                    <div class="text-center mt-2"><span class="contactUsLinkMobile text-decoration-underline" style="color: #005851;" @click="redirectToContact">Contact Us</span></div>
                    <div style="height: 1px; background-color: #46C3B2; width: 100%; margin-top: 1px;"></div>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else fluid id="q-dt-nav-container">
            <v-row>
                <v-col cols="4">
                    <a href="https://quility.com/">
                        <v-img
                                alt="Quility Logo"
                                :src="require('../assets/Quility-Logo-Text-Only-Forest-Green-RGB.svg')"
                                max-height="30"
                                contain
                                style="margin-top: 18px;"
                        />
                    </a>
                </v-col>
                <v-col cols="4" class="text-md-center " v-if="progress_nav == true">
                    <div class="menu-main-nav-container mt-2" v-if="hasSteps && !$vuetify.breakpoint.xs" >
                        <div class="mb-2">
                            <div :class="step === 'yourInfo' ? 'steps active' : 'steps'" style="text-align: left">
                                Your Info
                            </div>
                            <div :class="step === 'yourQuote' ? 'steps active' : 'steps'">
                                Your Quote
                            </div>
                            <div :class="step === 'yourApplication' ? 'steps active' : 'steps'" style="text-align: right">
                                Your Application
                            </div>
                        </div>
                        <div class="progress-steps mx-10">
                            <v-progress-linear color="#46C3B2" rounded :value="stepperValue" height="8"></v-progress-linear>
                        </div>
                    </div>
                </v-col>
                <v-col cols="4" class="text-md-center hidden-md-and-down" v-if="progress_nav === false" style="color:black;font-family: montserrat, Sans-serif">
                    <v-row class="mt-md-4" no-gutters >
                        <v-col class="contactUsLink">
                            What We Offer
                        </v-col >
                        <v-col class="contactUsLink">
                            The Basics
                        </v-col>
                        <v-col class="contactUsLink">
                            About Us
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4">
                    <div v-if="isMobile" class="text-center mt-3"><span class="contactUsLinkMobile text-decoration-underline" style="color: #005851;" @click="redirectToContact">Contact Us</span></div>
                    <div v-else class="text-center mt-md-4"><span class="contactUsLink" @click="redirectToContact">Contact Us</span></div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" style="padding: 0px !important;">
                    <div style="height: 1px; background-color: #46C3B2; width: 100%; margin-top: 25px;"></div>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "NavBar",
    props: [name],
    mixins: [quilityMixin],
    data() {
        return {
            hasSteps: true,
            step: 'yourInfo',
            stepperValue:30,
            progress_nav: true,
            chatClick: false
        }
    },
    watch:{
        $route (){
            this.setNavbarItems(this.$route.path);
        }
    },
    mounted() {
        this.setNavbarItems(this.$route.path);
    },
    methods:{
        setNavbarItems(routePath) {
            if(routePath === '/') {
                this.step = 'yourInfo';
                this.stepperValue = 30;
            }
            else if(routePath === '/termQuote') {
                this.step = 'yourQuote';
                this.stepperValue = 60;
            }
            else if(routePath === '/quotes') {
                this.step = 'yourQuote';
                this.stepperValue = 60;
            }
            else if(routePath === '/thank-you') {
                this.progress_nav = false;
            }
        },
        redirectToContact() {
            window.location.href = "https://quility.com/contact/"
        }
    }
}
</script>

<style scoped>
    .menu-main-nav-container .steps {
        font-family: montserrat, Sans-serif;
        display: inline-block;
        font-size: 0.9rem;
        width: 33%;
        text-align: center;
        color: #aaaaaa;
        font-weight: normal;
    }

    .menu-main-nav-container .steps.active {
        color: #002421;
        font-weight: 500;
    }

    .q-main-navbar {
        font-family: montserrat, Sans-serif !important;
    }

    .contactUsLink {
        font-family: montserrat, Sans-serif;font-weight: normal;
        text-align: center;
        font-weight: 500;
    }
    .contactUsLinkMobile {
        font-family: montserrat, Sans-serif;
        font-weight: normal;
        font-weight: 500;
    }
    .contactUsLink:hover{
        border-bottom: 3px solid #46C3B2;
        font-weight: 500;
        /*line-height: 2rem;*/
        cursor:pointer;
    }

    #q-dt-nav-container {
        padding: 0px !important;
    }
</style>
