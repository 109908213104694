<template>
    <v-app v-if="ready">
        <NavBar v-if="quility_site" :name="$route.name"></NavBar>
        <NavBarSymmetry v-if="symmetry_site"></NavBarSymmetry>
        <v-main class="main-background">
            <v-container>
                <!-- <v-row v-if="isBot">
                    <v-col cols="12">
                        <div class="ma-auto" style="background-color: darkgreen; color: bisque;"> Trickery gambles with lives, happiness, and even destiny itself! </div>
                    </v-col>
                </v-row> -->
                <v-row align="center" justify="center">
                    <v-col class="col-xl-8 ">
                        <div class="main-container-div">
                            <router-view/>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <Footer v-if="quility_site"></Footer>
        <OC_Footer v-if="symmetry_site"></OC_Footer>
    </v-app>
</template>

<script>
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import NavBarSymmetry from "./components/NavBarSymmetry";
import OC_Footer from "./components/OC_Footer";
import quilityMixin from "@/mixins/quilityMixin";
import { load } from '@fingerprintjs/botd'

export default {
    name: 'App',
    data: () => ({
        site_owner: null,
        quility_site: true,
        symmetry_site: false,
        ready: null,
        isBot: false,
    }),
    mixins:[quilityMixin],
    components: {
        OC_Footer,
        NavBarSymmetry,
        NavBar,
        Footer
    },
    async created() {
        await this.checkIP()

        const route_path = this.$route.path.split('/');
        this.updateNavFooter(route_path[1]);
    },
    watch: {
        $route (to){
            const route_path = to.path.split('/');
            this.updateNavFooter(route_path[1]);
        }
    },
    methods: {
        updateNavFooter(routeName) {
            if(routeName == '') {return false}

            this.site_owner = (routeName === 'symmetry' || routeName === 'myquility') ? 'Symm' : 'quility';
            this.quility_site = (this.site_owner === 'quility');
            this.symmetry_site = (this.site_owner === 'Symm');
        },

        async checkIP()
        {
            const ste_url = process.env.VUE_APP_PROXY + 'verify-ip-address';
            try {
                let res = await fetch(ste_url,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                })
                let json_data = await res.json()
                if(json_data.refuse)
                {
                    window.location.href = process.env.VUE_APP_REFUSE_REDIRECT
                }
            } catch (error) {
                console.log(error)
                return null
            }
        },
    },
    async mounted(){

        // initialize split
        let client = await this.$split_client.latest

        try {
            await client.ready()

            const split_names = ["dtc-raq-form-cov-amount-change",'dtc-raq-form-meet-a-agent-cta','dtc-raq-form-otp','dtc-raq-form-turnstile','dtc-raq-monthly-daily']
            const split_name_map = {
                'dtc-raq-form-cov-amount-change': 'cov_amount',
                'dtc-raq-form-meet-a-agent-cta': 'mma_cta',
                'dtc-raq-form-otp': 'otp',
                'dtc-raq-form-turnstile': 'ts',
                'dtc-raq-monthly-daily': 'freq',
            }
            let treatments = client.getTreatmentsWithConfig(split_names)
            let treatment_data = []

            for (const split of split_names) {
                const treatment = treatments[split].treatment.toLowerCase()
                const configs = treatments[split].config ? JSON.parse(treatments[split].config) : null

                treatment_data.push({
                    name: split_name_map[split],
                    value: (treatment === 'on'),
                    configs
                })
            }

            this.$store.commit('updateTreatments', treatment_data)

            let botd = await load({
                monitoring: false // disables sending data to fingerprintjs
            })

            let res = botd.detect()
            console.log(res)

            // if it's a bot log it for now
            if(res.bot === true){
                let d = botd.getDetections()
                let c = botd.getComponents()

                console.log(d,c)
                const properties =  {
                    detections: JSON.stringify(d),
                    collections: JSON.stringify(c)
                }

                // alert('Trickery gambles with lives, happiness, and even destiny itself!')
                this.isBot = true
                console.log('Trickery gambles with lives, happiness, and even destiny itself!')
                client.track('anonymous', 'form.botd.opensource.detected', null, properties)
                this.$store.commit('updateFlag', true)
            }

            this.ready = true

        } catch (error) {
            console.error(error)
            this.ready = true

            // kill split instance
            await this.$split_client.delete()
        }

        // set agent state
        let site = null

        const route_path = this.$route.path.split('/');
        this.updateNavFooter(route_path[1]);

        if(this.quility_site === true){
            site = 'QUILITY'
        }
        else if(this.symmetry_site === true){
            site = 'OC'
        }

        const agentData = await this.getAgentInfo(site)
        this.$store.commit('updateAgent', agentData)
        this.$store.commit('updateSite', {site})

        if(this.site == 'OC' && !this.agent.HasOCDTCSubscription && this.agent.agentCode != process.env.VUE_APP_PERSONAL_CODE) {
            window.location.href = 'https://quility.com/'
        }

        if(this.$route.query.redirect_url){
            this.$store.commit('updateRedirectUrl', this.$route.query.redirect_url)
        }
        // initialize amplitude for corp dtc
        if(site === 'QUILITY'){
            await this.$amplitude_client.initialize()
        }

        // adding bugherd script
        const bugherdScript = document.createElement("script")
        bugherdScript.src = "https://www.bugherd.com/sidebarv2.js?apikey=3c8xzrs7qsrxzmqmiccivg"
        bugherdScript.type = "text/javascript"
        bugherdScript.async = true
        document.head.appendChild(bugherdScript)
    },
    computed: {
        agent(){
            return this.$store.state.agent
        }
    }
};
</script>

<style>
#app {
    font-family: montserrat freight-display-pro Sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #2c3e50;
    margin-top: 60px;
}

.main-background {
    /* background: white; */
    background: white;
}

.main-container-div {
    margin-top: 5%;
    /* background:white */
    background: white;

}

body #app .v-application {
    font-family: Montserrat freight-display-pro Sans-serif !important;
}

.v-list-item__content{
    font-size: 1.2rem;
    font-family: montserrat, Sans-serif !important;
}

.v-menu__content {
    margin-top: 1.2%;
}

.dialog-header-text {
    font-family: FreightDispProBlack-Regular, Black !important;
    color:black;
    font-weight: 900 !important;
    font-size: 1.7rem !important;
}

.dialog-context-title {
    font-family: Montserrat !important;
    font-weight: bold !important;
    font-size: 0.8rem;
    color:black;
}

.v-btn {
    text-transform: none !important;
}

@media only screen and (max-width: 768px) {
    label.v-label.theme--light {
        font-size: 0.8rem;
    }
}
</style>

