<template>
    <div v-if="isMobile" id="text-center" class="px-6">
        <v-img
            class="mx-auto"
            :src="require('../assets/Quigley-OTP-human-mobile-img.png')"
            height="153px"
            width="221px">
        </v-img>
        <div
            class="mx-auto"
            style="font: normal normal bold 17px/26px Montserrat; letter-spacing: 0.09px; color: #000000; opacity: 1; text-align: center; margin-top: 18px;"
        >
            Verify your phone number to continue your application.
        </div>
        <div
            class="mx-auto"
            style="font: normal normal 300 15px/23px Montserrat; letter-spacing: 0px; color: #000000; opacity: 1; text-align: center; margin-top: 8px;"
        >
            We’ve texted you a one-time code.*
        </div>
        <v-otp-input
            style="margin-top: 51px;"
            v-model="code"
            type="number"
        >
        </v-otp-input>
        <div class="q-error-text" v-if="retry">
            Please enter a valid code
        </div>
        <v-btn
            class="elevation-0 form-text button-text"
            large
            block
            depressed
            @click="verifyCode"
            color="q-secondary"
            :disabled="loading"
        >
            Continue
        </v-btn>
        <div
            v-if="retry"
            @click="sendCode"
            style="text-align: center; text-decoration: underline; font: normal normal 300 15px/23px Montserrat; letter-spacing: 0px; color: #000000; opacity: 1; margin-top: 20px;"
        >
            Resend code
        </div>
        <div style="text-align: center; font: normal normal 300 12px/20px Montserrat; letter-spacing: 0px; color: #000000; opacity: 1; margin-top: 23px;">
            *Message and data rates may apply.
        </div>
    </div>
    <div v-else class="text-center">
        <div>
            <v-img
                :src="require('../assets/Quigley-OTP-human-mobile-img.png')"
                height="153px"
                width="221px"
                style="display: inline-block; margin-left: 261px;"
            >
            </v-img>
            <div style="float: right; display: inline-block; margin-right: 200px;">
                <p style="text-align: left; font: normal normal bold 25px/35px Montserrat; letter-spacing: 0px; color: #000000;">
                    Verify your phone number to <br/> continue your application.
                </p>
                <p style="text-align: left; font: normal normal 300 17px/26px Montserrat; letter-spacing: 0px;">
                    We’ve texted you a one-time code.*
                </p>
            </div>
        </div>
        <v-otp-input
            v-model="code"
            :disabled="loading"
            type="number"
            style="width: 264px;"
            class="mx-auto"
        >
        </v-otp-input>
        <div class="q-error-text mt-n1 mb-4" v-if="retry">
            Please enter a valid code
        </div>
        <v-btn
            class="elevation-0 form-text mx-auto mt-1 button-text"
            @click="verifyCode"
            color="q-secondary"
            width="264px"
            height="32px"
            :disabled="loading"
            depressed
        >
            Continue
        </v-btn>
        <div
            v-if="retry"
            @click="sendCode"
            class="mt-1"
            style="text-align: center; text-decoration: underline; font: normal normal 300 15px/23px Montserrat; letter-spacing: 0px; color: #000000; opacity: 1;"
        >
            Resend code
        </div>
        <div style="text-align: center; font: normal normal 300 12px/20px Montserrat; letter-spacing: 0px; color: #000000; opacity: 1; margin-top: 23px;">
            *Message and data rates may apply.
        </div>
    </div>
</template>

<script>
    import quilityMixin from "@/mixins/quilityMixin";
    export default {
        name: 'OTPVerify',
        mixins: [quilityMixin],
        data: () => ({
            loading: false,
            code: null,
            verifyError: false,
            sendError: false,
        }),
        methods: {
            async verifyCode () {
                if(!this.loading){
                    this.loading = true
                }

                try {
                    // send verification code
                    const phone = '+1' + this.form.phone_number.replace('(','').replace(')','').replace('-','')
                    const ste_url = process.env.VUE_APP_PROXY + 'check-verification-code'

                    let res = await fetch(ste_url,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        },
                        body: JSON.stringify({phone, code: this.code}),
                    })
                    let json_data = await res.json()
                    console.log(json_data)

                    if(json_data.success === true){
                        // redirect to selected e-app

                        this.verifyError = false
                        if(this.selectedProduct === 'JH'){
                            this.launchJH()
                        } else {
                            this.launchQLT()
                        }
                    } else {
                        console.error('Verification error',json_data.message)
                        this.verifyError = true
                        this.loading = false
                    }
                } catch (error) {
                    console.error('Verification error',error)
                    this.verifyError = true
                    this.loading = false
                }
            },
            async sendCode (){
                if(!this.loading){
                    this.loading = true
                }

                // send verification code
                const phone = '+1' + this.form.phone_number.replace('(','').replace(')','').replace('-','')
                const ste_url = process.env.VUE_APP_PROXY + 'send-verification-code'

                try {
                    let res = await fetch(ste_url,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        },
                        body: JSON.stringify({phone}),
                    })
                    let json_data = await res.json()
                    console.log(json_data)
                    this.sendError = false
                } catch (error) {
                    console.error('sendCode error', error)
                    this.sendError = true
                }
                this.loading = false
            },
        },
        async mounted(){
            this.loading = true

            window.scrollTo(0,0)

            /*
                - if this page is reached and the form is null, then redirect to form page
                - if this page is reached and no product is selected, then redirect to the quotes page if there is quote data, otherwise redirect to form
            */

            const hasForm = this.isset(this.form.phone_number)
            const hasQuotes = this.isset(this.quoteData.quotes)
            const hasProduct = this.isset(this.selectedProduct)

            if(!hasForm) {
                if(this.isOCSite){
                    this.$router.push({path: `/symmetry/raq/${this.agent.agentCode}`})
                } else {
                    this.$router.push({name: 'GetAQuote'})
                }
            }

            if(!hasProduct) {
                if(hasQuotes) {
                    this.$router.push({name: 'multiple-quotes'})
                } else {
                    if(this.isOCSite){
                        this.$router.push({path: `/symmetry/raq/${this.agent.agentCode}`})
                    } else {
                        this.$router.push({name: 'GetAQuote'})
                    }
                }
            }

            await this.sendCode()
        },
        computed: {
            retry() {
                return this.sendError || this.verifyError
            },
        }
    }
</script>

<style>
    .form-text {
        font-family: "Montserrat", Sans-serif !important;
    }
    .fontless-body {
        font-size: 1rem!important;
        font-weight: 400;
        line-height: 1.5rem;
        letter-spacing: .03125em!important;
    }
    .q-error-text{
        color: red;
        padding-left: 16px;
        padding-right: 16px;
        overflow-wrap: break-word;
        hyphens: auto;
        line-height: 12px;
        font-size: 12px !important;
        font-family: "Montserrat", Sans-serif !important;
        text-align: center;
    }

    .button-text {
        color: #fff !important;
        font-family: Montserrat, SemiBold;
        font-weight: 600;
        font-size: 0.8rem;

        @media only screen and (max-width: 486px) {
            & {
                font-size: 0.7rem !important;
                img {
                    width: 12px;
                }
            }
        }
    }
</style>