import { SplitFactory } from '@splitsoftware/splitio'
import { v4 as uuidv4 } from 'uuid'

export default {
    install(app) {
        app.prototype.$split_client = {
            cur_factory: null,
            key: null,
            get latest(){
                if(this.cur_factory !== null){
                    return this.cur_factory
                }
                this.cur_factory = this.initialize()
                return this.cur_factory
            },
            initialize(keyCallback = null) {
                const key = (keyCallback !== null && keyCallback !== undefined) ? keyCallback() : this.generateKey()
                this.key = key
                const factory = SplitFactory({
                    core: {
                        authorizationKey: process.env.VUE_APP_SPLIT_API_KEY,
                        key
                    },
                    startup: {
                        readyTimeout: 1.5
                    }
                });
                return factory.client()
            },
            generateKey() {
                return uuidv4()
            },
            async reset() {
                if(this.cur_factory === null)
                {
                    this.cur_factory = this.initialize()
                }

                await this.cur_factory.destroy()
                return this.initialize()
            },
            async delete() {
                if(this.cur_factory === null) {
                    return
                }
                await this.cur_factory.destroy()
                this.cur_factory = null
            }
        }
    }
}
