import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
// import moment from "moment";
import VueCookies from 'vue-cookies';
import split from './plugins/split.js'
import amplitude from './plugins/amplitude.js'

Vue.config.productionTip = false

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

Vue.filter('formatDate', function(value) {
    if (value) {
        const d = new Date(value)
        let dob_m = '' + (d.getMonth() + 1)
        let dob_d = '' + d.getDate()
        let dob_y = d.getFullYear()

        if(dob_m.length < 2){
            dob_m = '0' + dob_m
        }

        if(dob_d.length < 2){
            dob_d = '0' + dob_d
        }

        return `${dob_y}-${dob_m}-${dob_d}`
    }
});

Vue.use(VueCookies);
Vue.use(split);
Vue.use(amplitude);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

