<template>
    <div class="scenario-mobile-title hidden-md-and-up">
        <v-card style="font-family: Montserrat" class="pa-4 elevation-0">
            <div class="text-center" style="color: #005851; font-weight: 800; font-size: 2rem;">
                Your {{ form.product_type }} Coverage
            </div>
            <span class="text-caption">This is an initial quote. Your final premium may vary.</span>
            <v-row class="mt-1">
                <v-col cols="6">
                    <div class="text-caption">Term Length</div>
                    <v-select
                        dense
                        outlined
                        hide-details="auto"
                        class="dropdown-style input-control-text"
                        ref="dd_term_length"
                        id="coverage_plan"
                        :items="computed_term_items"
                        v-model="m_term_length"
                        :disabled="q_loading"
                        @change="checkQuote"
                    >
                    </v-select>
                </v-col>
                <v-col cols="6">
                    <div class="text-caption">Coverage Amount</div>
                    <v-text-field
                        v-if="cov_amount_treatment"
                        outlined
                        v-model="m_coverage_amount"
                        hide-details="auto"
                        dense
                        class="input-control-text"
                        style="width: 400px"
                        type="text"
                        inputmode="numeric"
                        @blur="checkQuote"
                        :disabled="q_loading"
                        @input="formatCoverage"
                        :rules="[rules.required, rules.covLimit]"
                        ref="cov_amt_ref"
                        >
                    </v-text-field>
                    <v-select
                        v-else
                        id="coverage_amnt-mp_tl"
                        outlined
                        v-model="m_coverage_amount"
                        hide-details="auto"
                        :items="coverage_items"
                        dense
                        ref="dd_coverage_amount"
                        class="input-control-text"
                        :disabled="q_loading"
                        @change="checkQuote"
                    >
                    </v-select>
                </v-col>
            </v-row>
        </v-card>
        <Quote_Card_Container></Quote_Card_Container>
    </div>

</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";
import Quote_Card_Container from "./Quote_Card_Container.vue";

export default {
    mixins: [quilityMixin],
    name: "am_card_mobile",
    props: [
        'am_premium',
        'jh_premium',
        'am_premium_exist',
        'sureify_method',
        'jh_method',
        'tobacco_used',
        'coverage_amount',
        'term_length',
        'coverage_items',
        'plan_items',
        'callback',
        'btnDisabled',
        'loading',
        'quote_array',
        'zoho_id',
    ],
    data: () => ({
        amam_benefit_dialog: false,
        jh_benefit_dialog:false,
        edit_quote_dialog:null,
        m_coverage_amount: 0,
        m_term_length: 0,
        m_tobacco_used: 0,
        disable_btn: false,
        q_loading: false,
        current_term_length:null,
        current_coverage_amount: null,
    }),
    components: {
        Quote_Card_Container
    },
    mounted() {
        this.m_coverage_amount = this.cov_amount_treatment ? this.stripNonNumbericChars(this.form.coverage_amount) : this.form.coverage_amount
        this.m_term_length = this.form.coverage_plan
        this.current_coverage_amount = this.form.coverage_amount
        this.current_term_length = this.form.coverage_plan
    },
    methods:{
        process_eapps(mode) {
            if(mode  === 'jh') {
                this.$parent.gotoEapp();
            }
            else if(mode === 'sbli') {
                this.$parent.process_sbli();
            }
        },
        async checkQuote(){
            if(this.current_coverage_amount == this.m_coverage_amount && this.current_term_length == this.coverage_plan){
                return null
            }

            if(this.coverage_amount === null || this.coverage_amount === undefined) {
                return null
            }

            if (this.cov_amount_treatment && this.$refs.cov_amt_ref.validate() === false) {
                return null;
            }

            this.$store.commit('updateFetchingQuote', true)
            this.q_loading = true;
            let ste_url = process.env.VUE_APP_PROXY + 'get-quote';

            this.$store.commit('updateForm', {
                coverage_amount: this.m_coverage_amount,
                coverage_plan: this.m_term_length
            })

            let ref_site = (this.site === 'OC') ? this.redirectUrl : (this.$cookies.get('set_referring_url')) ? this.$cookies.get('set_referring_url') : document.referrer

            const formattedCovAmt = this.cov_amount_treatment ? this.form.coverage_amount.replace(/[^\d.-]/g, '') : this.form.coverage_amount

            let postParam = {
                gender: this.form.gender,
                productType:this.form.product_type,
                smoker:this.form.tobacco_used, //
                zip:this.form.zip_code,
                dob:this.form.dob_formatted,
                coverage_amount: formattedCovAmt, //
                term_length: this.form.coverage_plan, //
                firstName:this.form.first_name,
                lastName: this.form.last_name,
                email: this.form.email,
                state_id: this.form.state_id,
                city: this.form.city,
                age: this.form.age,
                entry_point: process.env.VUE_APP_LEADENTRYPOINT,
                phone: this.form.phone_number,
                zoho_id: this.zohoid,
                site:this.site,
                leadType: this.form.lead_type,
                agentInfo:{...this.agent, site:this.site},
                mode:'edit',
                referrer: ref_site,
                utm_source:this.$cookies.get('utm_source'),
                utm_medium: this.$cookies.get('utm_medium'),
                utm_campaign: this.$cookies.get('utm_campaign'),
                utm_token: this.$cookies.get('utm_token'),
                utm_content: this.$cookies.get('utm_content'),
                gclid: this.$cookies.get('gclid'),
                Spanish_Speaking: this.form.spanish_speaking,
            }

            try {
                let res = await fetch(ste_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(postParam),
                })
                let json_data = await res.json()

                if(json_data.success === true) {
                this.$parent.getPremiums(json_data.quotes);
                this.$store.commit('updateQuoteData', json_data)
                }

                this.current_coverage_amount = this.form.coverage_amount
                this.current_term_length = this.form.coverage_plan
            } catch (error) {
                console.error('editquoutedialog->checkQuote->error', error)
            }
            this.q_loading = false;
            this.$store.commit('updateFetchingQuote', false)
        },
        formatCoverage() {
            this.m_coverage_amount = this.stripNonNumbericChars(this.m_coverage_amount)
        },
    },
    computed: {
        computed_coverage_amount() {
            return new Intl.NumberFormat().format(this.form.coverage_amount)
        },
        computed_term_length() {
            return this.form.coverage_plan
        },
        computed_tobacco() {
            return this.form.tobacco_used
        },
        computed_term_items() {
            let filter_years = []

            if (this.form.age > 45 && this.form.age <= 50) {
                filter_years.push('30 Years')
            } else if (this.form.age > 50 && this.form.age <= 55) {
                filter_years.push(['30 Years', '25 Years'])
            } else if (this.form.age > 55 && this.form.age <= 60) {
                filter_years.push(['30 Years', '25 Years', '20 Years'])
            } else if (this.form.age > 60) {
                return [{text: '10 Years', value: '10 Years'}]
            }

            if (this.state_id != null && (this.form.state_id === 'FL' || this.form.state_id === 'ND')) {
                filter_years.push(['15 Years'])
            }

            return [
                { text: '10 Years', value: '10 Years' },
                { text: '15 Years', value: '15 Years' },
                { text: '20 Years', value: '20 Years' },
                { text: '25 Years', value: '25 Years' },
                { text: '30 Years', value: '30 Years' },
            ].filter(td => {return !filter_years.includes(td.text)})
        },
    },
}
</script>

<style scoped>
.scenario-mobile-title {
    font-family: Montserrat;
}
.q-card {
    font-family: Montserrat;
}
.coverage-title {

    color: #9B9B9B;font-size: 0.69rem;
}

.amount-box {
    background: #005851;color: white; cursor:pointer;
}

.amount div.premium {
    font-family: Montserrat, ExtraBold;
    font-size: 2.0rem;
    font-weight: 800;
    white-space: pre-wrap;
    word-break: keep-all;
}

.amount div.amount-per-month {
    font-weight: 300;
    font-size:0.69rem;
    margin-top:-5%
}

.get-started {
    color: #46C3B2;
    font-family: Montserrat, SemiBold;
    font-weight: 600;
    font-size:0.8rem
}

.check-mark {
    color:#46C3B2;font-size: 1rem;font-weight: normal;
}

.benefit-item {
    font-size: 0.82rem;font-weight: 300;font-family: Montserrat, Light;
    color:black;
}

@media only screen and (max-width: 768px) {
    .cov_sup {
        font-size:0.6rem;
    }

    .amount div.premium {
        font-size: 1.5rem;
    }
}

</style>
