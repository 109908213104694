<template>
    <v-card flat style="background: white;font-family: Montserrat;">
        <v-card-title class="card-title">
            Get a Free Quote Instantly
        </v-card-title>
        <v-card-text class="mt-4">
            <div class="row">
                <div class="col-1 ">
                    <img src="../assets/Quility_MP_Schedule-Consultation.svg" />
                </div>
                <div class="col-10 ml-4 pl-md-0" style="color:black; font-weight: 500;font-size: 1rem;">
                    Apply Online
                </div>
            </div>
            <div class="row">
                <div class="col-12 ml-15 pl-0 pl-md-3">
                    <img src="../assets/OR seperator.svg" />
                </div>
            </div>
            <div class="row">
                <div class="col-1">
                    <img src="../assets/Quility_MP_Live-Chat.svg" />
                </div>
                <div class="col-10 ml-4 pl-md-0" >
                    <label style="color:black; font-weight: 500;font-size: 1rem;">Schedule a Consultation With</label><br/>
                    <v-progress-linear
                        color="deep-purple"
                        indeterminate
                        rounded
                        style="width: 30%;"
                        height="3"
                        v-if="agentLoader"
                    ></v-progress-linear>
                    <div>{{agent.agencyName}}</div>
                    <div>{{agent.agentPreferredName}}</div>
                    <div><a :href="'tel:' + agent.agentPhone">{{ formattedPhoneNumber }}</a></div>
                    <div><a :href="'mailto:' + agent.agentEmail">{{ agent.agentEmail }}</a></div>
                    <div v-if="agent.OCDTCAvatar" style="margin-top: 10px;" class="agent_profile_photo">
                        <v-img :aspect-ratio="1" :src="agent.OCDTCAvatar"></v-img>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>

import quilityMixin from "@/mixins/quilityMixin";
export default {
    name: "SymmRaqAgentInfo",
    computed:{
        formattedPhoneNumber() {
            if(!this.agent.agentPhone) {
                return null
            }
            const parts = this.agent.agentPhone.split("-")
            return `(${parts[0]}) ${parts[1]}-${parts[2]}`
        },
        agentLoader() {
            return this.agent == null || this.agent == undefined
        },
    },
    mixins:[quilityMixin],
}
</script>

<style scoped>
.card-title {
    font-family: Montserrat; color:#46C3B2; font-weight: bold;
}

.v-skeleton-loader .v-skeleton-loader__list-item-two-line {
    background: white !important;
}

.agent_profile_photo {
    height: 150px;
    width: 150px;
    border-radius: 50% !important;
    border: 2px solid #000000;
    overflow: hidden;
    text-align: center;
    display: inline-block;
    position: relative;
    margin: 0 auto;
    background-color: white;
}

.agent_profile_photo img {
    width: auto;
    height: 150px;
    position: absolute;
    margin: 0 auto;
    bottom: 0;
    right: 0;

}

</style>