<template>
    <div v-if="isMobile" class="row pb-5">
        <div class="col-md-7 col-xs-12 pr-4">
            <v-card flat style="background: white;">
                <v-card-text>
                    <p class="estimate-msg">
                        Instant life insurance. Instant peace of mind!
                    </p>
                    <p id="m-secondary-text">
                        Get your free quote and apply online in minutes — no exams, no hassle.
                    </p>
                    <v-img style="margin-top: 40.74px;" :src="require('../assets/Mobile_Process-Graphic.svg')"></v-img>
                </v-card-text>
            </v-card>
        </div>
        <div class="col-md-5 col-xs-12 pa-md-8">
            <RAQForm></RAQForm>
            <div id="m-bbb-notice">
                <v-row>
                    <v-col cols="12">
                        <span id="m-bbb-top-text">Keeping Your Info Secure</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="9">
                        <div id="m-bbb-bottom-text">
                            <span>We may email, text or call you about your application. To learn more about how we're
                                keeping your data safe, visit our <a target="_blank" href="https://quility.com/privacy-policy" >Privacy Policy</a>.</span>
                        </div>
                    </v-col>
                    <v-col cols="3">
                        <v-img id="m-bbb-logo" :src="require('../assets/BBB-Logo.svg')"></v-img>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
    <div v-else class="row pb-5">
        <div class="col-md-5 col-xs-12 pa-md-8">
            <RAQForm></RAQForm>
        </div>
        <div class="col-md-7 col-xs-12 pr-4">
            <v-card flat style="background: white;">
                <v-card-text>
                    <p id="d-main-text" style="margin-top: 100px;">Instant life insurance. Instant peace of mind!</p>
                    <p id="d-secondary-text" style="margin-top: 43px;">Get your free quote and apply online in minutes — no
                        exams, no hassle.</p>
                </v-card-text>
                <v-img style="margin-top: 98.87px;" :src="require('../assets/Desktop_Process-Graphic.svg')"></v-img>
                <div id="d-bbb-notice">
                    <v-row>
                        <v-col cols="12">
                            <span id="d-bbb-top-text">Keeping Your Info Secure</span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="10">
                            <div id="d-bbb-bottom-text">
                                <span>We may email, text or call you about your application. To learn more about how we're
                                    keeping your data safe, visit our <a target="_blank" href="https://quility.com/privacy-policy" >Privacy Policy</a>.</span>
                            </div>
                        </v-col>
                        <v-col cols="2">
                            <v-img id="d-bbb-logo" :src="require('../assets/BBB-Logo.svg')"></v-img>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </div>
    </div>
</template>
<script>
import quilityMixin from "@/mixins/quilityMixin";
import RAQForm from "./RAQForm";

export default {
    name: "GetAQuote",
    components: {
        RAQForm,
    },
    mixins: [quilityMixin],
    data: () => ({
        valid: false,
        agentData: {},
        product_type: 'Term Life'
    }),
    methods: {
        openZChat() {
            this.openZohoChat();
        }

    },
    async mounted() {
        const productType = this.$route.query.p;
        this.product_type = (typeof productType != 'undefined' && productType.toUpperCase() === 'MP') ? 'Mortgage Protection' : 'Term Life';
    }
}
</script>
<style scoped>
a:link, a:visited, a:hover, a:active {
    color: #46C3B2;
}

.get-a-quote-title {
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    text-align: left;
    line-height: 1em;
    color: #002421;
}

.estimate-msg {
    font-size: 3rem;
    color: #46C3B2;
    font-family: freight-display-pro, Sans-serif !important;
    line-height: 1.3em;
    font-weight: 900;
}

.reg-thin-text {
    margin-top: 3%;
    font-size: calc(16px + (20 - 16) * ((100vw - 400px) / (2000 - 400))) !important;
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    line-height: 1.5rem;
    color: #000000;
}

.med-thin-text {
    margin-top: 3%;
    font-size: calc(18px + (35 - 22) * ((100vw - 400px) / (2000 - 400))) !important;
    font-family: "Montserrat", Sans-serif !important;
    font-weight: 300;
    line-height: 2.5rem;
    color: #000000;
}

.breaking-border {
    background: #DAF3F0;
    width: 51.58px;
    height: 8.38px;
    margin-top: 2%;
}


.price-teaser-period {
    font-size: 0.8rem;
}

.dialog-text {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 0.8rem;
    color: black;
}

.theme--light.v-btn.v-btn--has-b {
    background-color: #005951;
    color: white;
}

#d-main-text {
    width: 766px;
    height: 128px;
    text-align: left;
    font: normal normal 900 51px/66px Montserrat;
    letter-spacing: 0px;
    color: #46C3B2;
    opacity: 1;
}

#d-secondary-text {
    width: 766px;
    height: 26px;
    text-align: left;
    font: normal normal 300 20px/30px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

#d-bbb-notice {
    top: 773px;
    left: 801px;
    width: 682px;
    height: 140px;
    background: #f0faf9 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-top: 108px;
    padding-top: 28.07px;
    padding-bottom: 31.31px;
}

#d-bbb-top-text {
    text-align: left;
    font: normal normal 600 15px/26px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    margin-left: 29.99px;
    margin-top: 28.07px;
}

#d-bbb-bottom-text {
    text-align: left;
    font: normal normal 300 15px/23px Montserrat;
    letter-spacing: 0.07px;
    color: #000000;
    margin-left: 29.99px;
}

#d-bbb-logo {
    width: 44px;
    height: 71px;
    margin-top: -44px;
}

#m-main-text {
    text-align: center;
    font: normal normal 900 27px/38px Montserrat;
    letter-spacing: 0px;
    color: #46C3B2;
    opacity: 1;
}

#m-secondary-text {
    width: 344px;
    height: 51px;
    text-align: left;
    font: normal normal 300 17px/26px Montserrat;
    letter-spacing: 0.09px;
    color: #000000;
    opacity: 1;
    margin-top: 16px;
}

#m-bbb-notice {
    background: #f0faf9 0% 0% no-repeat padding-box;
    border-radius: 10px;
    margin-top: 21px;
    padding-top: 22px;
    /* padding-bottom: 31.31px; */
}

#m-bbb-top-text {
    text-align: left;
    font: normal normal bold 13px/26px Montserrat;
    letter-spacing: 0px;
    color: #000000;
    margin-left: 18px;
    margin-top: 28.07px;
}

#m-bbb-bottom-text {
    text-align: left;
    font: normal normal 300 13px/20px Montserrat;
    letter-spacing: 0.07px;
    color: #000000;
    margin-left: 18px;
}

#m-bbb-logo {
    width: 44px;
    height: 71px;
    margin-top: -44px;
}

@media only screen and (max-width: 768px) {
    .estimate-msg {
        font: normal normal 900 27px/38px Montserrat !important;
        font-size: 2rem;
        line-height: 1.2em;
        margin-bottom: 0;

    }

    .get-a-quote-block {
        font-size: 0.8rem;
        white-space: pre-wrap;
        word-break: keep-all;
    }

    .price-teaser {
        margin-top: 14%;
    }

    .price-teaser-period {
        margin-top: 6%;
        font-size: 0.8rem;
        white-space: nowrap;
        word-break: keep-all;
    }

    input::-webkit-input-placeholder {
        color: red;
    }

    input:-moz-placeholder {
        color: red;
    }


}</style>