export default {
    data:() => ({
        chatClick: false,
        agentInfo: {},
        defaultAgent:{
            'agentName': process.env.VUE_APP_QAGENTNAME,
            'agentPreferredName': process.env.VUE_APP_QAGENTNAME,
            'agencyName': process.env.VUE_APP_QAGENCYNAME,
            'agentPhone': process.env.VUE_APP_QAGENTPHONE,
            'agentBPhone': process.env.VUE_APP_QAGENTBPHONE,
            'agentEmail': process.env.VUE_APP_QAGENTEMAIL,
            'agentPayrollID': process.env.VUE_APP_QAGENTPAYROLL,
            'agentGovtId': process.env.VUE_APP_QAGENTID,
            'agentAddress': process.env.VUE_APP_QAGENTADDRESS,
            'agentCity': process.env.VUE_APP_QAGENTCITY,
            'agentState': process.env.VUE_APP_QAGENTSTATE,
            'agentZip': process.env.VUE_APP_QAGENTZIP,
            'agentCode': process.env.VUE_APP_QAGENTCODE,
            'agentFirstName': process.env.VUE_APP_QAGENTFIRSTNAME,
            'agentLastName': process.env.VUE_APP_QAGENTLASTNAME,
            'amam_writingNumbers': null,
            'Signature': null,
            'agentLicenses': ['CA','AK','AL','AR','AZ','CO','CT','DC',
                'DE','FL','GA','HI','IA','ID','IL','IN','KS','KY','LA','MA',
                'MD','ME','MI','MN','MO','MS','MT','NC','ND','NE','NH','NJ','NM',
                'NV','NY','OH','OK','OR','PA','PR','RI','SC','SD','TN','TX','UT',
                'VA','VT','WA','WI','WV','WY'],
            'AffiliateCode': null,
        },
        defaultOCAgent:{
            'agentName': process.env.VUE_APP_OCAGENTNAME,
            'agentPreferredName': process.env.VUE_APP_OCAGENTNAME,
            'agencyName': process.env.VUE_APP_OCAGENCYNAME,
            'agentPhone': process.env.VUE_APP_OCAGENTPHONE,
            'agentBPhone': process.env.VUE_APP_OCAGENTBPHONE,
            'agentEmail': process.env.VUE_APP_OCAGENTEMAIL,
            'agentPayrollID': process.env.VUE_APP_OCAGENTPAYROLL,
            'AgentID': process.env.VUE_APP_OCAGENTID,
            'agentAddress': process.env.VUE_APP_OCAGENTADDRESS,
            'agentCity': process.env.VUE_APP_OCAGENTCITY,
            'agentState': process.env.VUE_APP_OCAGENTSTATE,
            'agentZip': process.env.VUE_APP_OCAGENTZIP,
            'agentCode': process.env.VUE_APP_OCAGENTCODE,
            'agentFirstName': process.env.VUE_APP_OCAGENTFIRSTNAME,
            'agentLastName': process.env.VUE_APP_OCAGENTLASTNAME,
            'amam_writingNumbers': {
                'Occidental': '0000079768',
                'amam':'0000105370'
            },
            'Signature': null,
            'OCDTCAvatar': null,
            'agentLicenses': ['NC','AK','AL','AR','AZ','CA','CO','CT','DC',
                'DE','FL','GA','HI','IA','ID','IL','IN','KS','KY','LA','MA',
                'MD','ME','MI','MN','MO','MS','MT','ND','NE','NH','NJ','NM',
                'NV','NY','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT',
                'VA','VT','WA','WI','WV','WY'],
            'AffiliateCode': null,
        },
        sbli_card_info:{
            logo: 'QLT-image.png',
            messages: [
                'Instant decision term coverage up to 30 years and $1M',
                'Option to convert to lifetime',
                'Charitable giving feature',
                'Optional accidental death protection',
            ],
            method: 'process_sbli',
        },
        jh_card_info:{
            logo: 'John-Hancock_Carrier-Logos.svg',
            messages: [
                'Rewards and discounts for living a healthy life',
                'Instant decision and coverage'
            ],
            method: 'gotoEapp'
        },
        chat_card_info:{
            logo: 'Conversation-Quote-Icon-WG.svg',
            messages: [
                'Lifetime support for all your policy needs',
                'Free virtual or in-person consults',
                'Licensed agents shop from 80+ insurance companies to find your best price!'
            ],
        },
        rules: {
            required: value => !!value || 'Required.',
            dob: v => (!!v === true) && !isNaN(Date.parse(v)) || 'Enter a valid date (MM/DD/YYYY).',
            zipcode: v => (!!v === true) && Number.isInteger(Number(v)) && Number(v) < 99999 && (v !== null && v.length == 5) || 'Enter a valid zip code',
            phone: v => {
                if(!!v === false){
                    return 'Enter a valid phone number (XXX-XXX-XXXX).'
                }
                const p = v.replace(/[^\d.]/g, '')
                return (Number.isInteger(Number(p)) && p.length == 10) || 'Enter a valid phone number (XXX-XXX-XXXX).'
            },
            email: v => {
                if(!!v === false){
                    return 'Enter a valid email address.'
                }

                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(v) || 'Enter a valid email address.'
            },
            covLimit: v => {
                if(!!v === false){
                    return 'Please enter an amount between $25,000 and $1,000,000.'
                }

                if((typeof v) == 'number'){
                    v = v.toString()
                }

                const n = Number(v.replace(/[^\d.-]/g, ''))
                return (!!v === true) && (n <= 1000000 && n >= 25000) || 'Please enter an amount between $25,000 and $1,000,000.'
            },
        },
    }),
    methods: {
        async updateDisposition(lead_id, disposition, redirect_url) {
            const parameters = this.$route.params;
            const api_url = process.env.VUE_APP_PROXY + 'update-disposition';
            const param = {
                zoho_id: this.zohoid,
                disposition: disposition
            }

            try {
                let res = await fetch(api_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(param),
                })
                let json_data = await res.json()

                this.redirecting = false;
                if(json_data.success === true && redirect_url!=null) {
                    if(parameters.site === 'OC') {
                        window.location.href = parameters.oc_redirect_url + '/request-a-quote/thanks/'
                    } else {
                        window.location.href = redirect_url
                    }

                }else {
                    return json_data
                }
            } catch (error) {
                this.redirecting = false
                throw `updateLeadProductPremium error: ${error}`
            }
        },

        async updateLeadProductPremium(form_data, lead_info, application) {
            const api_url = process.env.VUE_APP_PROXY + 'update-lead-premium';
            const post_param = {form_data, lead_info, application, 'state_id': form_data.state_id};

            try {
                let res = await fetch(api_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(post_param),
                })
                let json_data = await res.json()

                return json_data
            } catch (error) {
                throw `updateLeadProductPremium error: ${error}`
            }
        },
        openZohoChat() {
            window.$zoho.salesiq.chat.start();
            window.$zoho.salesiq.floatwindow.visible("show");
        },
        async getAgentInfo(site) {
            const ste_url = process.env.VUE_APP_PROXY + 'get-agent-detail';
            const parameters = this.$route.params;

            if(site === 'QUILITY') {
                this.agentInfo = this.getDefaultQuilityAgent();
                return this.agentInfo;
            }

            // console.log(`Brandons code: ${process.env.VUE_APP_PERSONAL_CODE}`, `current agent code:${parameters.agent_code}`)
            if(this.isPersonalCheck(parameters.agent_code)) {
                const res = await this.getPersonalAgent(parameters.agent_code)
                return res
            }

            try {
                let res = await fetch(ste_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify({'agent_code':parameters.agent_code}),
                })
                let json_data = await res.json()
                const a_data = json_data.data

                if(typeof a_data == 'undefined' || a_data.AgentName === '') {
                    return this.getDefaultOCAgent(parameters.agent_code);
                }

                return {
                    'agentName': a_data.AgentName,
                    'agentPreferredName': a_data.AgentPreferredName,
                    'agentFirstName': a_data.FirstName,
                    'agentLastName': a_data.LastName,
                    'agencyName': this.agencyName,
                    'agentPhone': a_data.AgentPhone,
                    'agentEmail': a_data.AgentEmail,
                    'agentPayrollID': a_data.JHWritingNumber,
                    'AgentID': a_data.AgentID,
                    'agentAddress': a_data.AddressLine1,
                    'agentCity': a_data.City,
                    'agentState': a_data.State,
                    'agentZip': a_data.Zip,
                    'agentCode': a_data.AgentCode,
                    'agentLicenses': a_data.licensedStates,
                    'amam_writingNumbers': a_data.amam,
                    'Signature': a_data.Signature,
                    'HasOCDTCSubscription': a_data.HasOCDTCSubscription,
                    'OCDTCAvatar': a_data.OCDTCAvatar,
                    'AffiliateCode': a_data.AffiliateCode,
                    'C': a_data.C,
                    'SB': a_data.SB
                }

            } catch (error) {
                console.error(error);
                this.agentInfo = this.getDefaultOCAgent(parameters.agent_code);
                return this.agentInfo;
            }
        },

        getDefaultQuilityAgent() {
            return {
                'agentName': process.env.VUE_APP_QAGENTNAME,
                'agentPreferredName': process.env.VUE_APP_QAGENTNAME,
                'agencyName': process.env.VUE_APP_QAGENCYNAME,
                'agentPhone': process.env.VUE_APP_QAGENTPHONE,
                'agentBPhone': process.env.VUE_APP_QAGENTBPHONE,
                'agentEmail': process.env.VUE_APP_QAGENTEMAIL,
                'agentPayrollID': process.env.VUE_APP_QAGENTPAYROLL,
                'agentGovtId': process.env.VUE_APP_QAGENTID,
                'agentAddress': process.env.VUE_APP_QAGENTADDRESS,
                'agentCity': process.env.VUE_APP_QAGENTCITY,
                'agentState': process.env.VUE_APP_QAGENTSTATE,
                'agentZip': process.env.VUE_APP_QAGENTZIP,
                'agentCode': process.env.VUE_APP_QAGENTCODE,
                'agentFirstName': process.env.VUE_APP_QAGENTFIRSTNAME,
                'agentLastName': process.env.VUE_APP_QAGENTLASTNAME,
                'amam_writingNumbers': null,
                'Signature': null,
                'agentLicenses': ['CA','AK','AL','AR','AZ','CO','CT','DC',
                    'DE','FL','GA','HI','IA','ID','IL','IN','KS','KY','LA','MA',
                    'MD','ME','MI','MN','MO','MS','MT','NC','ND','NE','NH','NJ','NM',
                    'NV','NY','OH','OK','OR','PA','PR','RI','SC','SD','TN','TX','UT',
                    'VA','VT','WA','WI','WV','WY'],
                'AffiliateCode': null,
                'C': {
                    'SBLI': true,
                    'JH': true
                }
            }
        },

        getDefaultOCAgent() {
            return {
                'agentName': process.env.VUE_APP_OCAGENTNAME,
                'agentPreferredName': process.env.VUE_APP_OCAGENTNAME,
                'agencyName': process.env.VUE_APP_OCAGENCYNAME,
                'agentPhone': process.env.VUE_APP_OCAGENTPHONE,
                'agentBPhone': process.env.VUE_APP_OCAGENTBPHONE,
                'agentEmail': process.env.VUE_APP_OCAGENTEMAIL,
                'agentPayrollID': process.env.VUE_APP_OCAGENTPAYROLL,
                'AgentID': process.env.VUE_APP_OCAGENTID,
                'agentAddress': process.env.VUE_APP_OCAGENTADDRESS,
                'agentCity': process.env.VUE_APP_OCAGENTCITY,
                'agentState': process.env.VUE_APP_OCAGENTSTATE,
                'agentZip': process.env.VUE_APP_OCAGENTZIP,
                'agentCode': process.env.VUE_APP_OCAGENTCODE,
                'agentFirstName': process.env.VUE_APP_OCAGENTFIRSTNAME,
                'agentLastName': process.env.VUE_APP_OCAGENTLASTNAME,
                'amam_writingNumbers': {
                    'Occidental': '0000079768',
                    'amam':'0000105370'
                },
                'Signature': null,
                'OCDTCAvatar': null,
                'agentLicenses': ['NC','AK','AL','AR','AZ','CA','CO','CT','DC',
                    'DE','FL','GA','HI','IA','ID','IL','IN','KS','KY','LA','MA',
                    'MD','ME','MI','MN','MO','MS','MT','ND','NE','NH','NJ','NM',
                    'NV','NY','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT',
                    'VA','VT','WA','WI','WV','WY'],
                'AffiliateCode': null,
                'C': {
                    'SBLI': true,
                    'JH': true
                }
            }
        },

        logEvent(category, label, action) {
            if (typeof gtag == 'function') {
                gtag('event', action, {'event_category': category,'event_label': label,});  // eslint-disable-line
            }
        },

        logFBConversion(category, label, action) {
            try {
                if (typeof fbq == 'function') {
                    fbq('track', action, {'content_category': category,'content_type': label,}); // eslint-disable-line
                }
            } catch (err) {
                console.log("whooppsie fb : " + err.message);
            }
        },

        logBingConversion(category, label, action) {
            try {
                window.uetq = window.uetq || [];
                if (typeof window.uetq == 'object') {
                    action = action.replace(' ', '_');
                    label = label.replace(' ', '_');
                    category = category.replace(' ', '_');
                    window.uetq.push('event', action, {
                        'event_category': category,
                        'event_label': label,
                        'event_value': 1
                    });
                }
            } catch (err) {
                console.log("whooppsie bing: " + err.message);
            }
        },

        isPersonalCheck(agentCode) {
            return agentCode == process.env.VUE_APP_PERSONAL_CODE
        },

        async getPersonalAgent() {
            let agent = this.getDefaultOCAgent()
            let hqAgentData = await this.getPersonalHQData() // used to get the signature and headshot urls

            agent.agentName = process.env.VUE_APP_PERSONAL_FULL_NAME
            agent.agentPreferredName = process.env.VUE_APP_PERSONAL_FULL_NAME
            agent.agentEmail = process.env.VUE_APP_PERSONAL_EMAIL
            agent.agentCode = process.env.VUE_APP_PERSONAL_CODE
            agent.agentFirstName = process.env.VUE_APP_PERSONAL_FIRST_NAME
            agent.agentLastName = process.env.VUE_APP_PERSONAL_LAST_NAME
            agent.amam_writingNumbers = {
                'Occidental': process.env.VUE_APP_PERSONAL_OCCIDENTAL,
                'amam': process.env.VUE_APP_PERSONAL_AMAM
            }
            agent.agentPhone = null
            agent.Signature = hqAgentData.Signature ? hqAgentData.Signature : process.env.VUE_APP_PERSONAL_SIGNATURE
            agent.OCDTCAvatar = hqAgentData.OCDTCAvatar ? hqAgentData.OCDTCAvatar : null

            return agent
        },

        async getAgentSignatureLink(agentCode) {
            agentCode = (agentCode == process.env.VUE_APP_PERSONAL_CODE) ? process.env.VUE_APP_OCAGENTCODE : agentCode
            const ste_url = process.env.VUE_APP_PROXY + 'get-agent-detail';

            try {
                let res = await fetch(ste_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify({'agent_code':agentCode}),
                })
                let json_data = await res.json()

                if(typeof json_data.data == 'undefined' || json_data.data.AgentName === '') {
                    return null
                }
                return json_data.data.Signature
            } catch (error) {
                console.error(error)
                return null
            }
        },

        async getPersonalHQData() {
            const ste_url = process.env.VUE_APP_PROXY + 'get-agent-detail';

            try {
                let res = await fetch(ste_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify({'agent_code':process.env.VUE_APP_OCAGENTCODE}),
                })
                let json_data = await res.json()
                return json_data.data
            } catch (error) {
                console.log(error)
                return null
            }
        },

        date_diff_days(s, e){
            var start = new Date(s);
            var end = new Date(e);
            var days = (end - start) / 1000 / 60 / 60 / 24;
            days = days - (end.getTimezoneOffset() - start.getTimezoneOffset()) / (60 * 24)

            return days
        },

        calculateAge(bd) {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const currentDay = currentDate.getDate();

            let calculatedAge = currentYear - bd.getFullYear();

            if (currentMonth < bd.getMonth() - 1) {
                calculatedAge--;
            }

            if (bd.getMonth() - 1 == currentMonth && currentDay < bd.getDate()) {
                calculatedAge--;
            }
            return calculatedAge;
        },

        formatNumber(maximumFractionDigits, minimumFractionDigits, n){
            let fc = new Intl.NumberFormat('en-IN', { maximumFractionDigits, minimumFractionDigits })
            let dfc = new Intl.NumberFormat()
            return (maximumFractionDigits > 0 || minimumFractionDigits > 0) ? fc.format(n) : dfc.format(n)
        },

        formatCurrency(a, maxDigits = 0) {
            let fc = new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: maxDigits,
            })
            return fc.format(a)
        },

        async routeToCESC() {
            let rerouted = false

            try {
                const payload = {
                    Product_Type: this.form.product_type,
                    Lead_Subtype: this.quoteData.zoho_payload.LeadSubType ?? null,
                    First_Name: this.form.first_name,
                    Last_Name: this.form.last_name,
                    Phone: this.form.phone_number,
                    Plan: this.form.coverage_plan,
                    Coverage_Amount: this.form.coverage_amount,
                    Premium_Frequency: 'Monthly',
                    Nicotine_User: this.form.tobacco_used,
                    Premium_Quoted_Amount: this.quoteData.zoho_payload.quote.premium_amount ?? 0,
                    City: this.form.city,
                    State: this.form.state_id,
                    Zip_Code: this.form.zip_code,
                    Email: this.form.email,
                    Street: null,
                    County: this.form.county,
                    DOB: this.form.dob_formatted,
                    Gender: this.form.gender,
                    Age: this.form.age,
                    Lead_Source: null,
                    zoho_id: this.zohoid,
                }
                const api_url = process.env.VUE_APP_PROXY + 'reroute-lead'
                let res = await fetch(api_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(payload),
                })
                let json_data = await res.json()

                rerouted = json_data.success
                if(rerouted === false){
                    console.error('routToCESC', json_data.message)
                }
            } catch (error) {
                console.error('routToCESC', error)
            }

            return rerouted
        },

        async routeToSwitchboardOC() {
            let rerouted = false

            try {
                const payload = {
                    Product_Type: this.form.product_type,
                    First_Name: this.form.first_name,
                    Last_Name: this.form.last_name,
                    Phone: this.form.phone_number,
                    Plan: this.form.coverage_plan,
                    Coverage_Amount: this.form.coverage_amount,
                    Premium_Frequency: 'Monthly',
                    Nicotine_User: this.form.tobacco_used,
                    Premium_Quoted_Amount: this.quoteData.zoho_payload.quote.premium_amount ?? 0,
                    Agent_Code: this.agent.agentCode,
                    City: this.form.city,
                    State: this.form.state_id,
                    County: this.form.county_name,
                    Zip: this.form.zip_code,
                    Email: this.form.email,
                    Sex: this.form.gender,
                    Birthday: this.form.dob_formatted,
                    Age: this.form.age,
                    MetaData: {
                        OC_DTC_RAQ_FORM_DATA: this.form,
                        ZOHO_ID: this.zohoid,
                        QUOTE_DATA: this.quoteData.quotes ?? null,
                    }
                }
                const api_url = process.env.VUE_APP_PROXY + 'reroute-lead-OC'
                let res = await fetch(api_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(payload),
                })
                let json_data = await res.json()

                rerouted = json_data.success
                if(rerouted === false){
                    console.error('routeToSwitchboardOC -> msg', json_data.message)
                    console.error('routeToSwitchboardOC -> data', json_data)
                }
            } catch (error) {
                console.error('routeToSwitchboardOC', error)
            }

            return rerouted
        },

        async FindAdvisor() {

            let rerouted

            if(this.site == 'OC'){
                // rerouted = await this.routeToSwitchboardOC()
                if(this.agent.SB){
                    let link_data = this.agent.SB.calendars.find(c => c.name.toLowerCase() === 'life insurance')

                    // use the first available calendar, if they don't have one for MP
                    if(!link_data) {
                        link_data = this.agent.SB.calendars[0]
                    }

                    if(this.isset(link_data.link)){
                        // kill split instance
                        await this.$split_client.delete()
                        window.location.href = link_data.link
                    } else {
                        rerouted = await this.routeToSwitchboardOC()
                    }
                } else {
                    rerouted = await this.routeToSwitchboardOC()
                }
            } else {
                rerouted = await this.routeToCESC()
            }

            if(rerouted){
                await this.updateDisposition(this.zohoid, 'RAQ Consult')
                this.$store.commit('updateForwardCesc', true)
                this.$router.push({name: 'Thankyou'});
            }
        },

        getImgUrl(pic) {
            return require('../assets/'+pic)
        },

        stripNonNumbericChars(n){
            if(n === null || n === undefined){
                return null
            }

            // remove non-numeric values and decimal values
            let covAmt = n.replace(/[^\d.-]/g, '')

            covAmt = new Intl.NumberFormat('en-US').format(covAmt)

            return (covAmt == "" || covAmt == "0") ? null : `$${covAmt}`
        },

        isset(value){
            // null and undefined evaluate to false
            // so false value would mean the value
            // is not 'set'
            return (value !== null && value !== undefined)
        },

        async launchQLT(){

            let client = await this.$split_client.latest
            const qlt_data = this.quoteData.quotes.find(q => q.Carrier === "SBLI")

            const lead_info = {
                'premium': qlt_data.Premium,
                'zoho_id': this.zohoid,
                'product': 'Quility Level Term',
                'disposition': 'RAQ Apply Now'
            }

            try {
                await this.updateLeadProductPremium(this.quoteParams.form_data, lead_info, 'sbli')
                const phone = this.form.phone_number
                let phone_clean = phone.replace('(', '')
                phone_clean = phone_clean.replace(')', '')

                const d = new Date(this.form.dob)
                let dob_m = '' + (d.getMonth() + 1)
                let dob_d = '' + d.getDate()
                let dob_y = d.getFullYear()

                if(dob_m.length < 2){
                    dob_m = '0' + dob_m
                }

                if(dob_d.length < 2){
                    dob_d = '0' + dob_d
                }

                const postParam = {
                    email: this.form.email,
                    leadReferenceId: this.zohoid,
                    phone: phone_clean,
                    zip: this.form.zip_code,
                    firstname: this.form.first_name,
                    lastname: this.form.last_name,
                    dob: `${dob_y}-${dob_m}-${dob_d}`,
                    gender: this.form.gender,
                    state: this.form.state_id,
                    coverage: this.form.coverage_amount,
                    term: this.form.coverage_plan,
                    smoker: this.form.tobacco_used,
                    arcId: qlt_data.arcId,
                    agentCode: (this.site === 'OC') ? this.agent.agentCode : process.env.VUE_APP_QLTCORPAGENT,
                    AffiliateCode: this.agent.AffiliateCode,
                }

                let ste_url = process.env.VUE_APP_PROXY + 'get-sbli-url';

                try {
                    let res = await fetch(ste_url,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        },
                        body: JSON.stringify(postParam),
                    })
                    let json_data = await res.json()

                    if (json_data.success == false) {
                        lead_info['disposition'] = 'Quote Error';
                        await this.updateLeadProductPremium(this.quoteParams, lead_info, 'sbli')
                        this.eApp_dialog = false
                        return false
                    }

                    client.track('anonymous', 'form.eapp.start')
                    if(this.site === 'OC'){
                        client.track('anonymous', 'form.eapp.ocdtc.qlt.start')
                    } else {
                        client.track('anonymous', 'form.eapp.qlt.start')
                    }
                    // kill split instance
                    await this.$split_client.delete()

                    window.location.href = json_data.data.redirectUrl
                } catch (error) {
                    // kill split instance
                    await this.$split_client.delete()

                    console.error('multiplequotes->process_sbli->error', error)
                }
            } catch (error) {
                console.error('multiplequotes->process_sbli->error', error)
                this.error_dialogue = true
                this.eApp_dialog = false
            }
        },

        async launchJH(){
            let client = await this.$split_client.latest
            const jh_data = this.quoteData.quotes.find(q => q.Carrier === "John Hancock")

            console.log('jh data', jh_data)

            this.$store.commit('updateEnteringEApp', true)
            this.$store.commit('updateSelectedProduct', 'JH')
            this.eApp_dialog = true;
            const phone_clean = this.form.phone_number.replace('(','').replace(')','')

            const d = new Date(this.form.dob)
            let dob_m = '' + (d.getMonth() + 1)
            let dob_d = '' + d.getDate()
            let dob_y = d.getFullYear()

            if(dob_m.length < 2){
                dob_m = '0' + dob_m
            }

            if(dob_d.length < 2){
                dob_d = '0' + dob_d
            }

            const param_premium = this.formatNumber(2,2, jh_data.Premium)
            const queryParam = {
                firstName: this.form.first_name,
                lastName: this.form.last_name,
                dob: `${dob_m}-${dob_d}-${dob_y}`,
                gender: this.form.gender,
                state: this.form.state_id,
                phone: phone_clean,
                coverageAmount: new Intl.NumberFormat().format(this.form.coverage_amount),
                coverageLength: this.form.coverage_plan.substring(0, 2),
                smoker: this.form.tobacco_used === 'Yes' ? 'Smoker' : 'NonSmoker',
                healthClass: 'Excellent',
                estimateMonthly: param_premium,
                email: this.form.email,
                applicantId: this.zohoid,
                salesSource: 'ain',
                salesSourceVariation: 'quility',
                productType: 'lft',
                agentEmail: this.agent.agentEmail,
                agentId: this.agent.agentGovtId ?? null,
                agentLicense: this.agent.agentLicenses,
                agentLicenseState: this.form.state_id,
                agentName: this.agent.agentName,
                agentPhone: this.agent.agentPhone,
                agentAddress: this.agent.agentAddress,
                agentCity: this.agent.agentCity,
                agentState: this.agent.agentState,
                agentZip: this.agent.agentZip,
                agentPayrollID: this.agent.agentPayrollID,
                customSkin: 'quility',
                quility_site: (this.site === 'OC') ? 'oc' : 'quility',
                token: process.env.VUE_APP_JH_TOKEN,
            }

            let query_string = Object.keys(queryParam).map(function(key) {
                return key + '=' + queryParam[key]
            }).join('&')

            const redirect_url = process.env.VUE_APP_JH_EAPP_URL + 'processor/intake?' + query_string
            const lead_info = {
                'premium': parseFloat(jh_data.Premium).toFixed(2),
                'zoho_id': this.zohoid,
                'product': jh_data.Product,
                'disposition': 'RAQ Apply Now',
            }

            try {
                await this.updateLeadProductPremium(this.quoteParams.form_data, lead_info, 'JH')

                client.track('anonymous', 'form.eapp.start')
                if(this.site === 'OC'){
                    client.track('anonymous', 'form.eapp.ocdtc.jh.start')
                } else {
                    client.track('anonymous', 'form.eapp.jh.start')
                }
                // kill split instance
                await this.$split_client.delete()

                window.location.href = redirect_url
            } catch (error) {
                // kill split instance
                await this.$split_client.delete()

                console.error('jhQuoteCard->gotoEapp->error',error)
                this.error_dialogue = true
                this.eApp_dialog = false
            }
        },
        // yes I stole this from Stack Overflow
        // no I don't feel bad about it
        getMonthsBetween(date1,date2,roundUpFractionalMonths)
        {
            //Months will be calculated between start and end dates.
            //Make sure start date is less than end date.
            //But remember if the difference should be negative.
            var startDate=date1;
            var endDate=date2;
            var inverse=false;
            if(date1>date2)
            {
                startDate=date2;
                endDate=date1;
                inverse=true;
            }

            //Calculate the differences between the start and end dates
            var yearsDifference=endDate.getFullYear()-startDate.getFullYear();
            var monthsDifference=endDate.getMonth()-startDate.getMonth();
            var daysDifference=endDate.getDate()-startDate.getDate();

            var monthCorrection=0;
            //If roundUpFractionalMonths is true, check if an extra month needs to be added from rounding up.
            //The difference is done by ceiling (round up), e.g. 3 months and 1 day will be 4 months.
            if(roundUpFractionalMonths===true && daysDifference>0)
            {
                monthCorrection=1;
            }
            //If the day difference between the 2 months is negative, the last month is not a whole month.
            else if(roundUpFractionalMonths!==true && daysDifference<0)
            {
                monthCorrection=-1;
            }

            return (inverse?-1:1)*(yearsDifference*12+monthsDifference+monthCorrection);
        }
    },
    computed: {
        agent(){
            return this.$store.state.agent
        },
        form(){
            return this.$store.state.form
        },
        site(){
            return this.$store.state.site
        },
        zohoid(){
            return this.$store.state.zoho_id
        },
        isMobile(){
            return this.$vuetify.breakpoint.mobile
        },
        quoteData(){
            return this.$store.state.quote_data
        },
        fetchingQuote(){
            return this.$store.state.fetching_quote
        },
        enteringEApp(){
            return this.$store.state.entering_e_app
        },
        forwardCesc(){
            return this.$store.state.forward_cesc
        },
        redirectUrl(){
            return this.$store.state.redirect_url
        },
        treatments(){
            return this.$store.state.treatments
        },
        isQuotePage() {
            return this.$route.path === '/quotes'
        },
        cov_amount_treatment() {
            return this.treatments.cov_amount.value
        },
        mma_cta_treatment() {
            return this.treatments.mma_cta.value
        },
        mma_cta_configs() {
            return this.treatments.mma_cta.configs
        },
        isOCSite() {
            return this.site === 'OC'
        },
        tky() {
            return this.$store.state.TRCKERYGAMBLESWITLiesHppnessAdEDstnytse
        },
        showChat() {
            return this.$store.state.showChat
        },
        otp_treatment() {
            return this.treatments.otp.value
        },
        selectedProduct() {
            return this.$store.state.selected_product
        },
        quoteParams() {
            return this.$store.state.quote_params
        },
        ts_treatment() {
            return this.treatments.ts.value
        },
        main_frequency() {
            return this.freq_treatment ? 'daily' : 'monthly'
        },
        secondary_frequency() {
            return this.freq_treatment ? 'monthly' : 'daily'
        },
        amp_event_data() {
            let {
                    age,
                    city,
                    county_name,
                    coverage_amount, coverage_plan,
                    dob,
                    dob_formatted,
                    email,
                    first_name,
                    gender,
                    last_name,
                    lead_type,
                    phone_number,
                    product_type,
                    spanish_speaking,
                    state_id,
                    tobacco_used,
                    zip_code
                } = this.form

            let jhData = this.quoteData.quotes.find(q => q.Carrier === "John Hancock")
            let sbliData = this.quoteData.quotes.find(q => q.Carrier === "SBLI")

            return {
                age,
                city,
                county_name,
                coverage_amount,
                coverage_plan,
                dob,
                dob_formatted,
                email,
                first_name,
                gender,
                last_name,
                lead_type,
                phone_number,
                product_type,
                spanish_speaking,
                state_id,
                tobacco_used,
                zip_code,
                quote_qlt_carrier: sbliData?.Carrier ?? null,
                quote_qlt_coverage: sbliData?.Coverage ?? null,
                quote_qlt_frequency: sbliData?.Mode ?? null,
                quote_qlt_product: sbliData?.Product ?? null,
                quote_qlt_term: sbliData?.term ?? null,
                quote_jh_carrier: jhData?.Carrier ?? null,
                quote_jh_coverage: jhData?.Coverage ?? null,
                quote_jh_frequency: jhData?.Mode ?? null,
                quote_jh_product: jhData?.Product ?? null,
                quote_jh_term: jhData?.Term ?? null,
            }

        },
        freq_treatment() {
            return this.treatments.freq.value
        }
    }
};
