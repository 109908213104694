<template>
    <v-dialog
            width="500"
            v-model="term_length_dialog"
    >
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" tabindex="-1" color="#005851">
                mdi-help-circle-outline
            </v-icon>
        </template>
        <v-card>
            <v-card-title class="dialog-header-text">
                Term Length
                <v-spacer></v-spacer>
                <v-icon large color="#46C3B2" @click="term_length_dialog = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text class="mt-0 ">
                <v-divider></v-divider>
                <div class="mt-2 dialog-text">
                    Your "term" is how long your policy will be in force. For
                    mortgage protection insurance, you may consider matching the
                    term of your mortgage.
                </div>
                <div class="mt-3">
                    <h3 class="dialog-context-title">
                        Have questions?</h3>
                    <div style="font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;">
                        Get in touch with us today at <a :href="'tel:'+agent.agentPhone" style="color:#46C3B2;" id="TLDialogTelNo">{{agent.agentPhone}}</a> or
                        <span style="color: #46C3B2;text-decoration: underline; cursor: pointer;" id="TLDialogChatRef"><a data-sm-show-media-selection-on="click" href="javascript:void(0);" style="color: #46C3B2;text-decoration: underline; cursor: pointer;">Chat with a licensed agent</a></span>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "term_length_help_dialog",
    mixins: [quilityMixin],
    props:{},
    data: () => ({
        term_length_dialog: false,
    }),
    methods:{},
}
</script>

<style scoped>
    .dialog-text {
        font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
    }
</style>
