<template  >
    <v-dialog
            width="500"
            v-model="coverage_amt_dialog"
    >
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" tabindex="-1" color="#005851">
                mdi-help-circle-outline
            </v-icon>
        </template>
        <v-card>
            <v-card-title class="dialog-header-text">
                Coverage Amount
                <v-spacer></v-spacer>
                <v-icon large color="#46C3B2" @click="coverage_amt_dialog = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text class="mt-0 ">
                <v-divider></v-divider>
                <div class="mt-2 dialog-text">
                    Your coverage amount is the tax-free, lump sum that will be paid
                    to your beneficiaries if you were to pass away while the policy
                    is in force.
                </div>
                <div class="mt-3">
                    <h3 class="dialog-context-title">
                        Have questions?</h3>
                    <div style="font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;">
                        Get in touch with us today at <a :href="'tel:'+agent.agentPhone" style="color:#46C3B2;" id="dialogTelNo">{{agent.agentPhone}}</a> or
                        <span style="color: #46C3B2;text-decoration: underline; cursor: pointer;" id="dialogChatRef"><a data-sm-show-media-selection-on="click" href="javascript:void(0);" style="color: #46C3B2;text-decoration: underline; cursor: pointer;">Chat with a licensed agent</a></span>
                    </div>
                </div>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "cov_amt_help_dialog",
    mixins: [quilityMixin],
    props:{},
    data:() => ({
        coverage_amt_dialog:false,
    }),
    methods:{},
}
</script>

<style scoped>
.dialog-text {
    font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
}
</style>
