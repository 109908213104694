<template>
    <v-row style="font-family: montserrat, Sans-serif !important">
        <div class="col-md-12 align-content-center " >
            <v-card class=" mt-2 px-10 mb-16 " flat style="background: white">
                <v-row >
                    <v-col class="offset-small" >
                        <img src='../assets/Schedule-Consultation Icon Desktop.svg' class="calendar-icon" />
                    </v-col>
                </v-row>
            </v-card>

            <div class="mt-16 text-center box-title" >
                Meet an Advisor
            </div>
            <div class="text-center mt-5 advisor-option" >
                Discuss your options with an insurance advisor who can help you find your best-fit plan.
            </div>
            <div class="text-center mt-7" >
                <a href="#" style="text-decoration: none" >
                    <span style="color: #46C3B2; cursor: pointer;" @click="FindAdvisor">Find an Advisor
                        <img src="../assets/CTA Arrow.svg">
                    </span>
                </a>
            </div>
        </div>
    </v-row>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "ScheduleConsultation",
    mixins: [quilityMixin],
    data:() =>({
    }),
    mounted() {
    },
}
</script>

<style scoped>
    .advisor-option {
        font-family: Montserrat !important;
        font-size: 1.3rem;
        font-weight: 300;
        color: black;
    }

    .calendar-icon {
        width: 235px;
    }

    .offset-small {
        display: flex;
        justify-content: center;
    }

    .box-title {
        font: normal normal 900 46px/54px FreightDispProBlack-Regular; color: black;
        /*margin-top: 17%;*/
    }

    @media only screen and (max-width: 768px) {
        .calendar-icon {
            width: 120px;
        }

        .offset-small {
            margin-top: 5%;
        }

        /* .box-title {

        } */
    }
</style>
