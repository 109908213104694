<template>
    <div v-if="isMobile" class="row">
        <div class="col-md-7 col-xs-12 pr-4">
            <v-card flat style="background: white;">
                <v-card-text class="pt-0">
                    <p class="estimate-msg">
                        Protect life’s journey every step of the way.
                    </p>
                </v-card-text>
            </v-card>
        </div>
        <div class="col-md-5 col-xs-12 pa-md-8">
            <RAQForm></RAQForm>
        </div>
        <div class="col-xs-12">
            <SymmRaqAgentInfo></SymmRaqAgentInfo>
        </div>
    </div>
    <div v-else class="row">
        <div class="col-md-5 col-xs-12 pa-md-8">
            <RAQForm></RAQForm>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-6 pr-4">
            <v-card flat style="background: white;">
                <v-card-text class="pt-0">
                    <p class="estimate-msg">
                        Protect life’s journey every step of the way.
                    </p>
                    <div class="breaking-border"></div>
                    <p class="med-thin-text">
                        Simple, online and affordable coverage.
                    </p>
                    <p v-if="hasAgency" class="med-thin-text agency-stmt mt-10">
                        {{ this.agentInfo.agencyName }} is backed by Quility, an online platform designed to offer you a
                        simple ten-minute application for coverage.
                    </p>
                </v-card-text>
            </v-card>
            <div class="hidden-sm-and-down">
                <SymmRaqAgentInfo></SymmRaqAgentInfo>
            </div>
        </div>
    </div>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";
import SymmRaqAgentInfo from "./SymmRaqAgentInfo";
import RAQForm from "./RAQForm";



export default {
    name: "SymmRAQ",
    components: {
        RAQForm,
        SymmRaqAgentInfo
    },
    mixins: [quilityMixin],
    data: () => ({
        agentData: null,
        hasAgency: false,
        site_detail: {},
    }),
    methods: {
        openZChat() {
            this.openZohoChat();
        },
    },
    async mounted() {
        try {
            // redirect to q.com if this agent doesn't
            // have a subscription

            this.agentData = {
                'agentName': this.agent.agentName,
                'agentFirstName': this.agent.agentFirstName,
                'agentLastName': this.agent.agentLastName,
                'agentPreferredName': this.agent.agentPreferredName,
                'agencyName': this.agent.agencyName,
                'agentPhone': this.agent.agentPhone,
                'agentEmail': this.agent.agentEmail,
                'agentAddress': this.agent.agentAddress,
                'agentCity': this.agent.agentCity,
                'agentState': this.agent.agentState,
                'agentZip': this.agent.agentZip,
                'agentPayrollID': this.agent.agentPayrollID,
                'site': 'OC',
                'agentCode': this.agent.agentCode,
                'agentLicenses': this.agent.agentLicenses,
                'amam_writingNumbers': this.agent.amam_writingNumbers,
                'Signature': this.agent.Signature,
                'OCDTCAvatar': this.agent.OCDTCAvatar,
            }
            if (this.agent.agencyName != null && this.agent.agencyName != undefined) {
                this.hasAgency = true;
            }
        } catch (error) {
            console.error(error)
        }
    }
}
</script>

<style scoped>
.estimate-msg {
    font-size: 3rem;
    color: black;
    font-family: freight-display-pro, Sans-serif !important;
    line-height: 1.3em;
    font-weight: 900;
}

.med-thin-text {
    margin-top: 3%;
    font-size: 1.7rem;
    font-family: Montserrat, Light !important;
    font-weight: 300;
    line-height: 2.5rem;
    color: #000000;
}

.agency-stmt {
    font-family: Montserrat, Light !important;
    font-size: 1.3rem !important;
}

.breaking-border {
    background: #005851;
    width: 51.58px;
    height: 8.38px;
    margin-top: 2%;
}

.price-teaser-period {
    font-size: 0.8rem;
}

.dialog-text {
    font-family: Montserrat;
    font-weight: normal;
    font-size: 0.8rem;
    color: black;
}





@media only screen and (max-width: 768px) {
    .estimate-msg {
        font-family: freight-display-pro, Sans-serif !important;
        font-size: 2rem;
        line-height: 1.2em;
        margin-bottom: 0;

    }

    .get-a-quote-block {
        font-size: 0.8rem;
        white-space: pre-wrap;
        word-break: keep-all;
    }

    .price-teaser {
        margin-top: 14%;
    }

    .price-teaser-period {
        margin-top: 6%;
        font-size: 0.8rem;
        white-space: nowrap;
        word-break: keep-all;
    }

    input::-webkit-input-placeholder {
        color: red;
    }

    input:-moz-placeholder {
        color: red;
    }
}</style>
