import * as amplitude from '@amplitude/analytics-browser'

export default {
    install(app) {
        app.prototype.$amplitude_client = {
            async initialize(user_id = null){
                const API_KEY = process.env.VUE_APP_AMPLITUDE_API_KEY
                amplitude.init(API_KEY, user_id ?? undefined, { defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true }});
            },
            track(event, eventProperties = null){
                amplitude.track(event, eventProperties)
            }
        }
    }
}
