<template>
    <div>
        <v-container class="hidden-sm-and-down">
            <div class="large-device-container pa-md-10">
                <v-card style="font-family: Montserrat" class="pa-4 elevation-0">
                    <v-row align="center" justify="center">
                        <v-col cols="4">
                            <span class="text-center" style="color: #005851; font-weight: 800; font-size: 2rem;">Your {{ form.product_type }} Coverage</span>
                            <br>
                            <span class="text-caption" style="font-family: Montserrat !important">This is an initial quote. Your final premium may vary.</span>
                        </v-col>
                        <v-col cols="3">
                            <div>Term Length</div>
                            <v-select
                                dense
                                outlined
                                hide-details="auto"
                                class="dropdown-style input-control-text"
                                ref="dd_term_length"
                                id="coverage_plan"
                                :items="computed_term_items"
                                v-model="coverage_plan"
                                :disabled="loading"
                                @change="checkQuote"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="4">
                            <div>Coverage Amount</div>
                            <v-text-field
                                v-if="cov_amount_treatment"
                                outlined
                                v-model="coverage_amount"
                                hide-details="auto"
                                dense
                                class="input-control-text"
                                style="width: 400px"
                                type="text"
                                inputmode="numeric"
                                @blur="checkQuote"
                                @input="formatCoverage"
                                :disabled="loading"
                                :rules="[rules.required, rules.covLimit]"
                                ref="cov_amt_ref"
                                >
                            </v-text-field>
                            <v-select
                                v-else
                                id="coverage_amnt-mp_tl"
                                outlined
                                v-model="coverage_amount"
                                hide-details="auto"
                                :items="coverage_items"
                                dense
                                ref="dd_coverage_amount"
                                class="input-control-text"
                                :disabled="loading"
                                @change="checkQuote"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card>
                <Quote_Card_Container @showChat="launchChat"></Quote_Card_Container>
            </div>
            <v-dialog v-model="show_e_app_modal" persistent width="300" style="font-family: montserrat, Sans-serif">
                <v-card color="#005851" dark>
                    <v-card-text style="font-family: montserrat, Sans-serif; font-size: 1rem; line-height: 1rem;" class="pa-3">
                        Starting e-Application
                        <v-progress-linear indeterminate color="white" class="mb-0 mt-4"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
        <div class="hidden-md-and-up">
            <am_card_mobile
                :am_premium="amam_premium"
                :am_premium_exist="amam_premium_exist"
                :jh_premium="jh_premium"
                :jh_method="gotoEapp"
                :tobacco_used="m_tobacco_used"
                :coverage_amount="coverage_amount"
                :term_length="m_term_length"
                :coverage_items="coverage_items"
                :plan_items="plan_items"
                :callback="assignValues"
                :quote_array="computed_card_data"
                :zoho_id="zoho_id"
            >
            </am_card_mobile>
        </div>
        <QuoteErrorDialog v-model="error_dialogue"></QuoteErrorDialog>
         <div id="chat-wrapper">
            <!-- chat code will get injected here...hopefully -->
         </div>
    </div>
</template>
<script>
window._mfq = window._mfq || [];
(function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript";
    mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/bc45607e-c708-40a4-b5de-cfecbe5be187.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
})();

import am_card_mobile from "@/components/am_card_mobile";
import quilityMixin from "@/mixins/quilityMixin";
import QuoteErrorDialog from "./QuoteErrorDialog";
import Quote_Card_Container from "./Quote_Card_Container.vue";

export default {
    name: "MultipleQuotations",
    mixins: [quilityMixin],
    data: () => ({
        error_dialogue: false,
        amam_benefit_dialog: false,
        jh_benefit_dialog: false,
        edit_quote_dialog: null,
        eApp_dialog: false,
        amam_dialog_help: false,
        jh_dialog_help: false,
        loading: false,
        amam_premium: 0,
        amam_product: null,
        amam_premium_exist: false,
        jh_premium: 0,
        jh_product: null,
        sbli_premium: 0,
        selected_term_length: 0,
        selected_Coverage: 0,
        m_coverage_amount: 0,
        m_term_length: 0,
        m_tobacco_used: null,
        zoho_id: null,
        plan_items: [
            { text: '10 Years', value: '10 Years' },
            { text: '15 Years', value: '15 Years' },
            { text: '20 Years', value: '20 Years' },
            { text: '25 Years', value: '25 Years' },
            { text: '30 Years', value: '30 Years' },
        ],
        btnDisabled: false,
        dialog: false,
        getQuoteData: null,
        quoteContainerClass: null,
        agencyName: null,
        agentName: null,
        agentPhone: null,
        agentEmail: null,
        agentAddress: null,
        agentCity: null,
        agentState: null,
        agentZip: null,
        agentPayrollID: null,
        agentId: null,
        agentGovtId: null,
        card_data: [],
        state_id: null,
        arcId: null,
        agentInfo: null,
        tobacco_used: null,
        coverage_plan: null,
        coverage_amount: 0,
        rerouted: false,
        current_term_length:null,
        current_coverage_amount: null,
    }),
    components: {
        QuoteErrorDialog,
        am_card_mobile,
        Quote_Card_Container
    },
    mounted() {

        this.coverage_plan = this.form.coverage_plan
        this.coverage_amount = this.cov_amount_treatment ? this.stripNonNumbericChars(this.form.coverage_amount) : this.form.coverage_amount
        this.tobacco_used = this.form.tobacco_used

        this.current_coverage_amount = this.form.coverage_amount
        this.current_term_length = this.form.coverage_plan

        const parameters = this.$route.params;
        if (typeof parameters.form_data !== 'undefined') {
            this.getPremiums(parameters.quotes);
            this.assignValues(parameters.form_data, parameters.quotes);
            this.zoho_id = parameters.zoho !==null ? parameters.zoho.ZohoID : null;
            this.getQuoteData = parameters;
        }

        if (this.$vuetify.breakpoint.name === 'lg') {
            this.quoteContainerClass = 'large-device-container';
        }

        this.agentName = this.agent.agentName;
        this.agentPhone = this.agent.agentPhone;
        this.agentAddress = this.agent.agentAddress;
        this.agentCity = this.agent.agentCity;
        this.agentZip = this.agent.agentZip;
        this.agentState = this.agent.agentState;
        this.agentPayrollID = this.agent.PayrollID;
        this.agentGovtId = null; // need to figure out where this is coming from...again
        this.agentEmail = this.agent.agentEmail;

        if (this.site === 'OC') {
            const agentLicensed = this.agent.agentLicenses.includes(this.form.state_id)

            if (agentLicensed != true) {
                const agentInfo = this.getDefaultOCAgent(); //pick brandon to go to JH eApp
                this.agentName = agentInfo.agentName;
                this.agentPhone = agentInfo.agentPhone;
                this.agentAddress = agentInfo.agentAddress;
                this.agentCity = agentInfo.agentCity;
                this.agentZip = agentInfo.agentZip;
                this.agentState = agentInfo.agentState;
                this.agentPayrollID = agentInfo.agentPayrollID;
                this.agentGovtId = agentInfo.agentGovtId;
                this.agentEmail = agentInfo.agentEmail;
            }
        }

        this.generateQuoteCards();


        var x = null, // eslint-disable-line
            p, q, m,
            o = "32725",
            l = "ORDER ID",
            i = "TOTAL ORDER AMOUNT",
            c = "",
            k = "",
            g = "",
            j = "",
            u = "",
            shadditional = "";
        try { p = top.document.referer !== "" ? encodeURIComponent(top.document.referrer.substring(0, 512)) : "" } catch (n) { p = document.referrer !== null ? document.referrer.toString().substring(0, 512) : "" }
        try { q = window && window.top && document.location && window.top.location === document.location ? document.location : window && window.top && window.top.location && "" !== window.top.location ? window.top.location : document.location } catch (b) { q = document.location }
        try { m = parent.location.href !== "" ? encodeURIComponent(parent.location.href.toString().substring(0, 512)) : "" } catch (z) { try { m = q !== null ? encodeURIComponent(q.toString().substring(0, 512)) : "" } catch (h) { m = "" } }
        var A, y = document.createElement("script"),
            w = null,
            v = document.getElementsByTagName("script"),
            t = Number(v.length) - 1,
            r = document.getElementsByTagName("script")[t];
        if (typeof A === "undefined") { A = Math.floor(Math.random() * 100000000000000000) } w = "dx.mountain.com/spx?conv=1&shaid=" + o + "&tdr=" + p + "&plh=" + m + "&cb=" + A + "&shoid=" + l + "&shoamt=" + i + "&shocur=" + c + "&shopid=" + k + "&shoq=" + g + "&shoup=" + j + "&shpil=" + u + shadditional;
        y.type = "text/javascript";
        y.src = ("https:" === document.location.protocol ? "https://" : "http://") + w;
        r.parentNode.insertBefore(y, r);


    },
    created() {
        if (this.form === undefined && this.form === null) {
            this.$router.push({ name: 'GetAQuote' });
        }

        this.selected_term_length = this.form.coverage_plan
        this.selected_Coverage = this.formatNumber(0,0,this.form.coverage_amount)
    },
    methods: {
        getPremiums(quotes) {
            this.amam_premium_exist = false

            if(quotes){
                quotes.forEach((item) => {
                    if (item.Coverage == null || item.Premium == null) {
                        return;
                    }
                    this.selected_term_length = item.Term;
                    this.selected_Coverage = this.formatNumber(0,0,item.Coverage);

                    if (item.Carrier === 'John Hancock') {
                        this.jh_premium = parseFloat(item.Premium).toFixed(2);
                        this.jh_product = item.Product;
                    } else if (item.Carrier === 'SBLI') {
                        this.arcId = item.arcId;
                        this.sbli_premium = parseFloat(item.Premium).toFixed(2);
                    }
                });
            }
        },
        setCoverageItems(coverage_limit) {
            let r = []
            for (let i = 0; i < coverage_limit; i++) {
                let amt = (25000 + (i * 25000));
                r.push({
                    'text': this.formatCurrency(amt),
                    'value': amt
                })
            }
            r.reverse();
            return r;
        },
        assignValues(form_data, quotes) {
            // update form in the store
            this.$store.commit('updateForm', {
                tobacco_used: form_data.smoker,
                coverage_amount: form_data.coverage_amount,
                coverage_plan: form_data.term_length
            })

            this.getPremiums(quotes);
            this.generateQuoteCards();
        },
        async gotoEapp() {
            this.eApp_dialog = true;
            const parameters = this.$route.params;
            const phone_clean = this.form.phone_number.replace('(','').replace(')','')

            const d = new Date(this.form.dob)
            let dob_m = '' + (d.getMonth() + 1)
            let dob_d = '' + d.getDate()
            let dob_y = d.getFullYear()

            if(dob_m.length < 2){
                dob_m = '0' + dob_m
            }

            if(dob_d.length < 2){
                dob_d = '0' + dob_d
            }

            const queryParam = {
                firstName: this.form.first_name,
                lastName: this.form.last_last,
                dob: `${dob_m}-${dob_d}-${dob_y}`,
                gender: this.form.gender,
                state: this.form.state_id,
                phone: phone_clean,
                coverageAmount: this.formatNumber(2,2, this.form.coverage_amount),
                coverageLength: this.form.coverage_plan.substring(0, 2),
                smoker: this.form.tobacco_used === 'Yes' ? 'Smoker' : 'NonSmoker',
                healthClass: 'Excellent',
                estimateMonthly: this.jh_premium,
                email: this.form.email,
                applicantId: this.zohoid,
                salesSource: 'ain',
                salesSourceVariation: 'quility',
                productType: 'lft',
                agentEmail: this.agent.agentEmail,
                agentId: this.agent.agentGovtId ?? null,
                agentLicense: this.agent.agentLicenses,
                agentLicenseState: this.form.state_id,
                agentName: this.agent.agentName,
                agentPhone: this.agent.agentPhone,
                agentAddress: this.agent.agentAddress,
                agentCity: this.agent.agentCity,
                agentState: this.agent.agentState,
                agentZip: this.agent.agentZip,
                agentPayrollID: this.agent.agentPayrollID,
                customSkin: 'quility',
                quility_site: (this.site === 'OC') ? 'oc' : 'quility',
                token: process.env.VUE_APP_JH_TOKEN,
            }

            let query_string = Object.keys(queryParam).map(function(key) {
                return key + '=' + queryParam[key]
            }).join('&')

            const redirect_url = process.env.VUE_APP_JH_EAPP_URL + 'processor/intake?' + query_string
            const lead_info = {
                'premium': this.jh_premium,
                'zoho_id': this.zohoid,
                'product': this.jh_product,
                'disposition': 'RAQ Apply Now',
            }

            try {
                await this.updateLeadProductPremium(parameters.form_data, lead_info, 'JH')
                window.location.href = redirect_url
            } catch (error) {
                console.error('multiplequotes->gotoEapp->error',error)
                this.error_dialogue = true
                this.eApp_dialog = false
            }
        },
        async process_sbli() {
            this.eApp_dialog = true
            const parameters = this.$route.params

            const lead_info = {
                'premium': this.sbli_premium,
                'zoho_id': this.zohoid,
                'product': 'Quility Level Term',
                'disposition': 'RAQ Apply Now'
            }

            try {
                await this.updateLeadProductPremium(parameters.form_data, lead_info, 'sbli')
                const phone = this.form.phone_number
                let phone_clean = phone.replace('(', '')
                phone_clean = phone_clean.replace(')', '')

                const d = new Date(this.form.dob)
                let dob_m = '' + (d.getMonth() + 1)
                let dob_d = '' + d.getDate()
                let dob_y = d.getFullYear()

                if(dob_m.length < 2){
                    dob_m = '0' + dob_m
                }

                if(dob_d.length < 2){
                    dob_d = '0' + dob_d
                }

                const postParam = {
                    email: this.form.email,
                    leadReferenceId: this.zohoid,
                    phone: phone_clean,
                    zip: this.form.zip_code,
                    firstname: this.form.first_name,
                    lastname: this.form.last_name,
                    dob: `${dob_y}-${dob_m}-${dob_d}`,
                    gender: this.form.gender,
                    state: this.form.state_id,
                    coverage: this.form.coverage_amount,
                    term: this.form.coverage_plan,
                    smoker: this.form.tobacco_used,
                    arcId: this.arcId,
                    agentCode: (this.site === 'OC') ? this.agent.agentCode : process.env.VUE_APP_QLTCORPAGENT,
                    AffiliateCode: this.agent.AffiliateCode,
                }

                let ste_url = process.env.VUE_APP_PROXY + 'get-sbli-url';

                try {
                    let res = await fetch(ste_url,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        },
                        body: JSON.stringify(postParam),
                    })
                    let json_data = await res.json()

                    if (json_data.success == false) {
                        lead_info['disposition'] = 'Quote Error';
                        await this.updateLeadProductPremium(parameters, lead_info, 'sbli')
                        this.eApp_dialog = false
                        return false
                    }

                    window.location.href = json_data.data.redirectUrl
                } catch (error) {
                    console.error('multiplequotes->process_sbli->error', error)
                }
            } catch (error) {
                console.error('multiplequotes->process_sbli->error', error)
                this.error_dialogue = true
                this.eApp_dialog = false
            }
        },
        generateQuoteCards() {
            const JH_state_filter = ['FL', 'NY'];
            const state_filter = ['NY'];
            // const parameters = this.$route.params;
            this.card_data = [];

            if (typeof this.jh_premium != 'undefined' && this.jh_premium > 0 && !JH_state_filter.includes(this.form.state_id)) {
                const jh_card = {
                    mode: 'jh',
                    premium: this.jh_premium,
                    logo: 'John-Hancock_Carrier-Logos.svg',
                    messages: [
                        'Rewards and discounts for living a healthy life',
                        'Instant decision and coverage'
                    ],
                    mobile_msg: [
                        'Rewards and discounts for living a healthy life',
                        'Instant decision and coverage'
                    ],
                    method: 'gotoEapp'
                }
                this.card_data.push(jh_card);
            }

            if (typeof this.sbli_premium != 'undefined' && this.sbli_premium > 0 && !state_filter.includes(this.form.state_id)) {
                const sbli_card = {
                    mode: 'sbli',
                    premium: this.sbli_premium,
                    logo: 'QLT-image.png',
                    mobile_logo_exists: true,
                    // sub_logo: 'Backed-By-SBLI.svg',
                    sub_logo_exists: false,
                    mobile_logo: 'QLT-image.png',
                    messages: [
                        'Coverage up to <sup>$</sup>1 Million',
                        '10 to 30-year terms',
                        'No medical exam<sup>1</sup>'
                    ],
                    mobile_msg: [
                        'Coverage up to <sup>$</sup>1 Million',
                        '10 to 30-year terms',
                        'No medical exam<sup>1</sup>'
                    ],
                    method: 'process_sbli'
                }
                this.card_data.push(sbli_card);
            }

            this.card_data.sort((a, b) => (parseInt(a.premium) < parseInt(b.premium)) ? -1 : 1);
        },
        async checkQuote() {
            if(this.current_coverage_amount == this.coverage_amount && this.current_term_length == this.coverage_plan){
                return null
            }

            if(this.coverage_amount === null | this.coverage_amount === undefined) {
                return null
            }

            if (this.cov_amount_treatment && this.$refs.cov_amt_ref.validate() === false) {
                return null;
            }

            this.$store.commit('updateFetchingQuote', true)
            this.loading = true;
            this.btnDisabled = true;
            let ste_url = process.env.VUE_APP_PROXY + 'get-quote';

            this.$store.commit('updateForm', {
                coverage_amount: this.coverage_amount,
                coverage_plan: this.coverage_plan,
            })

            let ref_site = (this.site === 'OC') ? this.redirectUrl : (this.$cookies.get('set_referring_url')) ? this.$cookies.get('set_referring_url') : document.referrer

            const formattedCovAmt = this.cov_amount_treatment ? this.form.coverage_amount.replace(/[^\d.-]/g, '') : this.form.coverage_amount

            let postParam = {
                gender: this.form.gender,
                productType:this.form.product_type,
                smoker:this.form.tobacco_used, //
                zip:this.form.zip_code,
                dob:this.form.dob_formatted,
                coverage_amount: formattedCovAmt, //
                term_length: this.form.coverage_plan, //
                firstName:this.form.first_name,
                lastName: this.form.last_name,
                email: this.form.email,
                state_id: this.form.state_id,
                city: this.form.city,
                age: this.form.age,
                entry_point: process.env.VUE_APP_LEADENTRYPOINT,
                phone: this.form.phone_number,
                zoho_id: this.zohoid,
                site:this.site,
                leadType: this.form.lead_type,
                agentInfo:{...this.agent, site:this.site},
                mode:'edit',
                referrer: ref_site,
                utm_source:this.$cookies.get('utm_source'),
                utm_medium: this.$cookies.get('utm_medium'),
                utm_campaign: this.$cookies.get('utm_campaign'),
                utm_token: this.$cookies.get('utm_token'),
                utm_content: this.$cookies.get('utm_content'),
                gclid: this.$cookies.get('gclid'),
                Spanish_Speaking: this.form.spanish_speaking,
            }

            try {
                let res = await fetch(ste_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(postParam),
                })
                let json_data = await res.json()

                this.loading = false
                this.btnDisabled = false

                 if(json_data.success === true) {
                    this.getPremiums(json_data.quotes);
                    this.$store.commit('updateQuoteData', json_data)
                 }

                this.current_coverage_amount = this.form.coverage_amount
                this.current_term_length = this.form.coverage_plan

            } catch (error) {
                console.error('editquoutedialog->checkQuote->error', error)
            }
            this.loading = false;
            this.$store.commit('updateFetchingQuote', false)
        },
        formatCoverage() {
            this.coverage_amount = this.stripNonNumbericChars(this.coverage_amount)
        },
        launchChat() {
            let chatWidget = document.createElement('chat-widget')
            chatWidget.style.cssText = "--chat-widget-primary-color: #015851; --chat-widget-active-color:#015851 ;--chat-widget-bubble-color: #015851"
            chatWidget.setAttribute('location-id', 'wF86A5YldTRvnaK7y7EO')
            chatWidget.setAttribute('use-email-field', 'true')
            chatWidget.setAttribute('prompt-avatar', 'https://quility.com/wp-content/uploads/2021/12/Get-to-know-Quigley_img.png')

            let gohighScript = document.createElement("script")
            gohighScript.src = "https://widgets.leadconnectorhq.com/loader.js"
            gohighScript.setAttribute('data-resources-url', 'https://widgets.leadconnectorhq.com/chat-widget/loader.js')

            let embeddedScriptNode = document.createElement("script")
            let embeddedScriptCodeNode = document.createTextNode(`
                var chat = document.querySelector("chat-widget");
                var checkExist = setInterval(function() {
                    if (document.querySelectorAll("chat-widget").length) {
                        console.log("Exists!");
                        clearInterval(checkExist);

                        let cesc = ['chBPMpf9duSSoBFaFMee', 'CXqn3UIkKY1AZNbATZMv', 'd9EVxHcHt7FSpdRYnZYY', 'Dw0JHNa4b6ywvLImNDZd', 'kGzhp0BSd4onrOW81NpH', 'LX6imQfuUuR7HRKAhXPM', 'mEgA6LHuXvy66BaaeE2a', 'rzlgna3FX46hadBRxklq', 'TswQxQGjJz1JwkJZHM8L', 'wF86A5YldTRvnaK7y7EO', 'wuGbRo35bt6OlUWT9WlU', 'yIVZHxT803qyB9fPa1Ba', 'zhDYCNEGH0qZkk6ZEb7K', 'zToaufnNx2SENiASSQT1'];

                        let selectLocation = cesc[Math.floor(Math.random() * cesc.length)];

                        chat.attributes['location-id'] = selectLocation;
                        console.log(chat.attributes['location-id'].value);
                    }
                }, 100); // check every 100ms
            `)

            embeddedScriptNode.type = "text/javascript"
            embeddedScriptNode.appendChild(embeddedScriptCodeNode)

            document.getElementById('chat-wrapper').appendChild(chatWidget).appendChild(embeddedScriptNode).appendChild(gohighScript)
        }
    },
    computed: {
        coverage_items: {
            get() {
                return this.setCoverageItems(40);
            },
            set(newCoverageItems) {
                return newCoverageItems
            }
        },
        computed_card_data: function() {

            if(this.isPersonalCheck(this.agent.agentCode)){
                return this.card_data.filter((card) => { return card.mode != 'jh'})
            }

            return this.card_data
        },
        do_show_no_qoutes_error() {
            if(!this.quoteData.quotes){
                // console.log('no quotes')
                return true
            }

            for (const q of this.quoteData.quotes) {
                // console.log('q', q,'Premium', q.Premium, 'isset?', this.isset(q.Premium))
                if(this.isset(q.Premium)) {
                // console.log('quotes', q)
                    return false
                }
            }

            // console.log('no quotes', this.quoteData.quotes)
            return true
        },
        computed_coverage_amount() {
            return new Intl.NumberFormat().format(this.form.coverage_amount)
        },
        computed_term_length() {
            return this.form.coverage_plan
        },
        computed_tobacco() {
            return this.form.tobacco_used
        },
        computed_term_items() {
            let filter_years = []

            if (this.form.age > 45 && this.form.age <= 50) {
                filter_years.push('30 Years')
            } else if (this.form.age > 50 && this.form.age <= 55) {
                filter_years.push(['30 Years', '25 Years'])
            } else if (this.form.age > 55 && this.form.age <= 60) {
                filter_years.push(['30 Years', '25 Years', '20 Years'])
            } else if (this.form.age > 60) {
                return [{text: '10 Years', value: '10 Years'}]
            }

            if (this.state_id != null && (this.form.state_id === 'FL' || this.form.state_id === 'ND')) {
                filter_years.push(['15 Years'])
            }

            return [
                { text: '10 Years', value: '10 Years' },
                { text: '15 Years', value: '15 Years' },
                { text: '20 Years', value: '20 Years' },
                { text: '25 Years', value: '25 Years' },
                { text: '30 Years', value: '30 Years' },
            ].filter(td => {return !filter_years.includes(td.text)})
        },
        show_e_app_modal() {
            return this.eApp_dialog || this.enteringEApp
        },
    },
}
</script>
<style scoped>
.large-device-container {
    font-family: Montserrat;
    /*width: 90%;*/
    /*align-content: center;*/
}
</style>