<template>
    <v-dialog
            width="500"
            v-model="tobacco_dialog"
    >
        <template v-slot:activator="{ on }">
            <v-icon v-on="on" tabindex="-1" color="#005851">
                mdi-help-circle-outline
            </v-icon>
        </template>
        <v-card>
            <v-card-title class="dialog-header-text">
                Tobacco Use
                <v-spacer></v-spacer>
                <v-icon large color="#46C3B2" @click="tobacco_dialog = false">
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-card-text class="mt-0">
                <v-divider></v-divider>
                <div class="mt-2 dialog-text">
                    Good news! Our preferred insurance partner allows occasional
                    pipe and cigar users to select "no."
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "tobacco_help_dialog",
    data: () => ({
        tobacco_dialog: false,
    })
}
</script>

<style scoped>
.dialog-text {
    font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
}
</style>
