<template>
    <div v-if="isMobile">
        <v-row>
            <v-col cols="12">
                <!-- SBLI -->
                <div v-if="showSBLI">
                    <QLTQuoteCard class="mx-auto"></QLTQuoteCard>
                </div>
                <!-- JH -->
                <div v-if="showJH">
                    <JHQuoteCard class="mx-auto"></JHQuoteCard>
                </div>
                <!-- Meet Agent CTA -->
                <MeetAnAgentCard class="mx-auto" v-on="$listeners"></MeetAnAgentCard>
            </v-col>
        </v-row>
    </div>
    <div v-else>
        <v-row>
            <!-- SBLI -->
            <v-col v-if="showSBLI" cols="4">
                <QLTQuoteCard></QLTQuoteCard>
            </v-col>
            <!-- JH -->
            <v-col v-if="showJH" cols="4">
                <JHQuoteCard></JHQuoteCard>
            </v-col>
            <!-- Meet Agent CTA -->
            <v-col cols="4">
                <MeetAnAgentCard v-on="$listeners"></MeetAnAgentCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";
import QLTQuoteCard  from "./QLTQuoteCard.vue"
import JHQuoteCard from "./JHQuoteCard.vue"
import MeetAnAgentCard from "./MeetAnAgentCard.vue";

export default {
    components: { QLTQuoteCard, JHQuoteCard, MeetAnAgentCard },
    name: "QuoteCardContainer",
    mixins: [quilityMixin],
    computed: {
        showSBLI() {
            return this.quoteData.quotes.some(q => q.Carrier === "SBLI" && q.Premium !== null && q.Premium !== undefined)
        },
        showJH() {
            return this.quoteData.quotes.some(q => q.Carrier === "John Hancock" && q.Premium !== null && q.Premium !== undefined)
        },
    },
}
</script>


<style lang="scss" scoped>
$text--secondary: #9B9B9B;

.amount {
    font-size: 1.8rem;
    font-weight: 800;
    letter-spacing: 0.32px;
}
.amount-per-month {
    font-size: 0.75rem;
    font-weight: normal;
}


.premium-note {
    color: $text--secondary;
    font-size: 0.5rem;
    text-align: left;
}

.get-started {
    color: #fff !important;
    font-family: Montserrat, SemiBold;
    font-weight: 600;
    font-size: 0.8rem;

    @media only screen and (max-width: 486px) {
        & {
            font-size: 0.7rem !important;
            img {
                width: 12px;
            }
        }
    }
}
</style>