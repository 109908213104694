<template>
    <div>
        <v-form @submit.prevent="requestQuote" ref="form" v-model="valid" id="raqForm">
            <v-card outlined tile class="elevation-0" style="border-radius: 10px !important;">
                <v-card-text class="pt-6 pb-6 pr-6 pl-6">
                    <v-row>
                        <v-col cols="7" sm="3" md="7">
                            <span class="input-control-text">Date of Birth</span>
                            <v-text-field
                                placeholder="MM/DD/YYYY"
                                outlined
                                v-model="dob"
                                :rules="[rules.dob, outside_age_range]"
                                hide-details="auto"
                                dense
                                v-mask="'##/##/####'"
                                class="input-control-text"
                                id="dob"
                                @focus="dob_focus = true"
                                style="width: 400px"
                                type="text"
                                inputmode="numeric"
                                >
                            </v-text-field>
                            <span class="input-control-text-small">You must be between 18 and 60 to qualify for coverage</span>
                        </v-col>
                        <v-col cols="5" sm="3" md="5">
                            <span class="input-control-text">Zip Code</span>
                            <v-text-field
                                outlined
                                :rules="[rules.zipcode]"
                                v-model="zipCode"
                                ref="fld_zipCode"
                                hide-details="auto"
                                class="input-control-text"
                                dense
                                placeholder="12345"
                                id="zip"
                                type="text"
                                inputmode="numeric"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="5" md="12">
                            <span class="input-control-text">Coverage Amount<Cov_amt_help_dialog></Cov_amt_help_dialog></span>

                            <v-text-field
                                v-if="cov_amount_treatment"
                                outlined
                                v-model="coverage_amount"
                                :rules="[rules.covLimit]"
                                hide-details="auto"
                                dense
                                class="input-control-text"
                                style="width: 400px"
                                type="text"
                                inputmode="numeric"
                                @input="formatCoverage"
                                placeholder="$25,000 - $1,000,000"
                                >
                            </v-text-field>
                            <v-select
                                v-else
                                id="coverage_amnt-mp_tl"
                                outlined
                                v-model="coverage_amount"
                                hide-details="auto"
                                :items="coverage_items"
                                dense
                                ref="dd_coverage_amount"
                                class="input-control-text mt-2"
                                :rules="[rules.covLimit]"
                                >
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <div class="input-control-text mb-2">Term Length <Term_length_help_dialog :agent-data="dialogData"></Term_length_help_dialog></div>
                                <div :class="termlengthError ? 'error-outline' : ''">
                                    <v-btn
                                        depressed
                                        class="ml-1 mt-1"
                                        v-for="(data,idx) in plan_items"
                                        :key="idx"
                                        @click="coverage_plan = data.value"
                                        :color="coverage_plan === data.value ? 'q-primary' : null"
                                        style="width:100px;"
                                    >
                                        <span class="input-control-text" style="width:80px;">{{data.text}}</span>
                                    </v-btn>
                                </div>
                                <div v-if="termlengthError" class="q-error-text font-weight-light">
                                    Required.
                                </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <div class="input-control-text mb-2">Gender at Birth <Gender_help_dialog></Gender_help_dialog></div>
                            <div :class="genderError ? 'error-outline' : ''">
                                <v-btn
                                    depressed
                                    class="ml-1 mt-1"
                                    @click="gender = 'Male'"
                                    :color="gender === 'Male' ? 'q-primary' : null"
                                    style="width:100px;"
                                >
                                    <span class="input-control-text" style="width:80px;">Male</span>
                                </v-btn>
                                <v-btn
                                    depressed
                                    class="ml-1 mt-1"
                                    @click="gender = 'Female'"
                                    :color="gender === 'Female' ? 'q-primary' : null"
                                    style="width:100px;"
                                >
                                    <span class="input-control-text" style="width:80px;">Female</span>
                                </v-btn>
                            </div>
                            <div v-if="genderError" class="q-error-text font-weight-light" style="color: red;">
                                Required.
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <div class="input-control-text">Tobacco Used in Last 12 Months <Tobacco_help_dialog></Tobacco_help_dialog></div>
                            <div :class="tobaccoError ? 'error-outline' : ''">
                                <v-btn
                                    depressed
                                    class="ml-1 mt-1"
                                    @click="tobacco_used = 'Yes'"
                                    :color="tobacco_used === 'Yes' ? 'q-primary' : null"
                                    style="width:100px;"
                                >
                                    <span class="input-control-text" style="width:80px;">Yes</span>
                                </v-btn>
                                <v-btn
                                    depressed
                                    class="ml-1 mt-1"
                                    @click="tobacco_used = 'No'"
                                    :color="tobacco_used === 'No' ? 'q-primary' : null"
                                    style="width:100px;"
                                >
                                    <span class="input-control-text" style="width:80px;">No</span>
                                </v-btn>
                            </div>
                            <div v-if="tobaccoError" class="q-error-text font-weight-light" style="color: red;">
                                Required.
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <div class="input-control-text mb-n8">Personal Information</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" sm="3" md="6">
                            <v-text-field
                                label="First Name"
                                outlined
                                :rules="[rules.required]"
                                v-model="firstName"
                                hide-details="auto"
                                class="input-control-text"
                                dense
                                id="first_name"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="3" md="6">
                            <v-text-field label="Last Name"
                                        outlined
                                        :rules="[rules.required]"
                                        v-model="lastName"
                                        hide-details="auto"
                                        class="input-control-text"
                                        dense
                                        id="last_name"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field label="Email Address"
                                        outlined
                                        :rules="[rules.email]"
                                        v-model="email"
                                        hide-details="auto"
                                        class="input-control-text"
                                        dense
                                        height="20"
                                        id="email"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <v-text-field
                                label="Phone Number"
                                outlined
                                :rules="[rules.phone]"
                                v-model="phoneNumber"
                                hide-details="auto"
                                class="input-control-text"
                                dense
                                placeholder="(###)-###-####"
                                v-mask="'(###)-###-####'"
                                id="phone"
                                type="text"
                                inputmode="numeric"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6" md="12">
                            <div class="input-control-text">Speak to a Representative in:</div>
                            <v-btn
                                depressed
                                class="ml-1 mt-1"
                                @click="Spanish_Speaking = false"
                                :color="Spanish_Speaking === false ? 'q-primary' : null"
                                style="width:100px;"
                            >
                                <span class="input-control-text" style="width:80px;">English</span>
                            </v-btn>
                            <v-btn
                                depressed
                                class="ml-1 mt-1"
                                @click="Spanish_Speaking = true"
                                :color="Spanish_Speaking === true ? 'q-primary' : null"
                            >
                                <span class="input-control-text" style="width:80px;">Spanish</span>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <div v-if="!otp_treatment && ts_treatment" class="pl-2">
                                <div id="cf-turnstile-container"></div>
                            </div>

                            <v-btn
                                class="input-control-text mt-4"
                                style="text-transform: none;"
                                type="button"
                                @click="sumitForm"
                                large block depressed dark
                                id="btnRAQ"
                                color="q-secondary"
                            >
                                Get My Quote
                            </v-btn>
                            <!-- <v-btn color="success" @click="test">test</v-btn> -->

                        </v-col>
                        <v-col v-if="quote_error">
                            <div style="font:italic normal 300 11px/16px Montserrat; color:#FF0000">
                                {{quote_error_message}}
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
        <v-dialog
            v-model="dialog"
            persistent
            width="300"
            style="font-family: montserrat, Sans-serif"
        >
            <v-card
                color="#005851"
                dark
            >
                <v-card-text style="font-family: montserrat, Sans-serif; font-size: 1rem; line-height: 1rem;"
                             class="pa-3">
                    Generating Quote
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0 mt-4"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <SubscriptionDialog v-model="subscription_dialog" :zoho-id="zohoid"></SubscriptionDialog>
    </div>
</template>

<script>
import SubscriptionDialog from "@/components/SubscriptionDialog";
import quilityMixin from "@/mixins/quilityMixin";
import Tobacco_help_dialog from "@/components/tobacco_help_dialog";
import Gender_help_dialog from "@/components/gender_help_dialog";
import Term_length_help_dialog from "@/components/term_length_help_dialog";
import Cov_amt_help_dialog from "@/components/cov_amt_help_dialog";

export default {
    name: "RAQForm",
    components: {
        Cov_amt_help_dialog,
        Term_length_help_dialog,
        Gender_help_dialog,
        Tobacco_help_dialog,
        SubscriptionDialog,
    },
    mixins:[quilityMixin],
    props: {
        plan_lists: {
            type: Object,
            default: function () {
                const terms = [
                    {text: '10 Years', value: '10 Years'},
                    {text: '15 Years', value: '15 Years'},
                    {text: '20 Years', value: '20 Years'},
                    {text: '25 Years', value: '25 Years'},
                    {text: '30 Years', value: '30 Years'},
                ];
                return {terms};
            }
        },
        amam_only_states: {
            type: Array,
            default: function() {
                return ['FL', 'ND'];
            }
        },
    },
    data: () => ({
        subscription_dialog: false,
        coverage_amt_dialog: false,
        term_length_dialog: false,
        gender_dialog: false,
        tobacco_dialog: false,
        valid: false,
        plan_items: [],
        coverage_amount: null,
        coverage_plan: null,
        gender: null,
        age: null,
        tobacco_used: null,
        zipCode: null,
        lastName: null,
        firstName: null,
        email: null,
        phoneNumber: null,
        coverage_limit: 40,
        coverage_items: [],
        fld_zipCode: 'fld_zipCode',
        state_id: null,
        city: null,
        zipError: null,
        quote_error: false,
        quote_error_message: null,
        select: [],
        dialog: false,
        api_url: process.env.VUE_APP_PROXY,
        agentInfo: {},
        leadType:'Quility',
        isConversion: false,
        Spanish_Speaking: false,
        dob: null,
        dob_focus: false,
        coverage_plan_meh:null,
        genderError: false,
        tobaccoError: false,
        termlengthError: false,
        create_time: null,
        submit_time: null,
        county: null,
        ts_validated: false,
        ts_id: null
    }),
    computed: {
        term_length_validate: function () {
            return value => !!value || 'Required.'
        },
        coverage_amount_validate: function () {
            return value => !!value || 'Required.'
        },
        zip_code_validate: function() {
            if(this.zipError === 'error') {
                return false || 'Zip code does not exist';
            }
            return v => Number.isInteger(Number(v)) && Number(v) < 99999 && (v !== null && v.length == 5) || 'Enter a valid zipcode'
        },
        outside_age_range(){
            if(this.age == null || this.age == undefined){
                return true
            }

            console.log('age', this.age)
            // return this.date_diff_days(this.dob, new Date()) > (18 * 365) || 'Age must be greater than 18'
            return (this.age >= 18 && this.age <= 60) || 'Please enter an age between 18 and 60'
        },
        dialogData(){
            return {
                'site': this.site,
                'agentPhone': this.agent.agentPhone
            }
        },
    },
    watch: {
        zipCode: function (value) {
            if (value.length === 5) {
                this.getStateByZipCode(value);
            }
        },
        coverage_limit: function(newValue) {
            this.coverage_items = this.setCoverageItems(newValue);
        },
        dob: function(newValue) {
            if(newValue.length < 10) {
                return false;
            }

            this.age = this.insuranceAge(newValue);
        },
        tobacco_used: function(newValue) {
            if(newValue != null && newValue != undefined){
                this.tobaccoError = false
            }
        },
        gender: function(newValue) {
            if(newValue != null && newValue != undefined){
                this.genderError = false
            }
        },
        coverage_plan: function(newValue) {
            if(newValue != null && newValue != undefined){
                this.termlengthError = false
            }
        },
        ts_validated: function(v) {
            if(v === true){
                setTimeout(() => {
                    turnstile.remove(this.ts_id)
                }, 1000);
            }
        }
    },
    methods:{
        setCoverageItems(limit) {
            let r = []
            for (let i = 0; i < limit; i++) {

                let amt = (25000 + (i * 25000));
                r.push({
                    'text': this.formatCurrency(amt),
                    'value': amt
                })
            }
            r.reverse();
            return r;
        },

        async getStateByZipCode(zipcode) {
            this.plan_items = [
                {text: '10 Years', value: '10 Years'},
                {text: '15 Years', value: '15 Years'},
                {text: '20 Years', value: '20 Years'},
                {text: '25 Years', value: '25 Years'},
                {text: '30 Years', value: '30 Years'},
            ];

            let ste_url = this.api_url + 'zip-code-quility';

            const postParam = {'zipcode':zipcode};

            try {
                let res = await fetch(ste_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(postParam),
                })
                let json_data = await res.json()

                this.zipError = '';
                if(json_data.status === 'error') {
                    this.zipError = 'error';
                    return false;
                }

                this.state_id = json_data.zip_info.state_id;
                this.city = json_data.zip_info.city;
                this.county = json_data.zip_info.county_name

            } catch (error) {
                console.error('raqform->getStateByZipCode->error', error)
            }
        },
        async requestQuote(e) {
            console.log('e',e)
            this.submit_time = new Date()
            let product_type = this.$route.query.p;
            let ste_url = this.api_url + 'get-quote';

            let client = await this.$split_client.latest

            // users who have clicked the Request A Quote button
            client.track('anonymous', 'form.click.request-quote')

            if(this.coverage_amount !== null && this.coverage_amount !== undefined){
                // users who have entered a value for the coverage amount
                // and clicked the Request A Quote button
                client.track('anonymous', 'form.dtc.coverage-amount-field-completion')
            }

            if(this.gender == null) {
                this.genderError = true
            }

            if(!this.tobacco_used) {
                this.tobaccoError = true
            }

            if(!this.coverage_plan) {
                this.termlengthError = true
            }

            if (this.$refs.form.validate() === false) {
                return false;
            }

            if(this.genderError || this.tobaccoError || this.termlengthError) {
                return false
            }

            if((!this.otp_treatment && this.ts_treatment) && this.ts_validated === false){
                turnstile.reset(this.ts_id)
                return false
            }

            this.dialog = true;

            if(this.Spanish_Speaking === true)
            {
                product_type = 'Spanish-MP';
            }
            else{
                product_type = (typeof product_type!='undefined' && product_type.toUpperCase() === 'MP') ? 'Mortgage Protection' : 'Term Life';
            }

            const d = new Date(this.dob)
            let dob_m = '' + (d.getMonth() + 1)
            let dob_d = '' + d.getDate()
            let dob_y = d.getFullYear()

            if(dob_m.length < 2){
                dob_m = '0' + dob_m
            }

            if(dob_d.length < 2){
                dob_d = '0' + dob_d
            }

            const q_agent = this.agent

            let ref_site = (this.site === 'OC') ? this.$route.query.redirect_url : (this.$cookies.get('set_referring_url')) ? this.$cookies.get('set_referring_url') : document.referrer

            const formattedCovAmt = this.cov_amount_treatment ? this.coverage_amount.replace(/[^\d.-]/g, '') : this.coverage_amount

            let postParam = {
                gender: this.gender,
                productType: product_type,
                smoker: this.tobacco_used,
                zip: this.zipCode,
                dob: `${dob_y}-${dob_m}-${dob_d}`,
                coverage_amount: formattedCovAmt,
                term_length: this.coverage_plan,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                phone: this.phoneNumber,
                state_id: this.state_id,
                city: this.city,
                age: this.age,
                entry_point: process.env.VUE_APP_LEADENTRYPOINT,
                mode: 'create',
                site:this.site,
                zoho_id: null,
                agentInfo:{...q_agent, site:this.site},
                leadType:this.leadType,
                referrer: ref_site,
                utm_source:this.$cookies.get('utm_source'),
                utm_medium: this.$cookies.get('utm_medium'),
                utm_campaign: this.$cookies.get('utm_campaign'),
                utm_token: this.$cookies.get('utm_token'),
                utm_content: this.$cookies.get('utm_content'),
                gclid: this.$cookies.get('gclid'),
                Spanish_Speaking: this.Spanish_Speaking,
                SetSupportStatus: this.tky
            }

            this.$store.commit('updateForm', {
                dob: this.dob,
                coverage_amount: this.coverage_amount,
                coverage_plan: this.coverage_plan,
                gender: this.gender,
                tobacco_used: this.tobacco_used,
                zip_code: this.zipCode,
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                phone_number: this.phoneNumber,
                spanish_speaking: this.Spanish_Speaking,
                state_id: this.state_id,
                city: this.city,
                product_type: product_type,
                age: this.age,
                lead_type: this.leadType,
                dob_formatted: `${dob_y}-${dob_m}-${dob_d}`,
                county_name: this.county,
            })

            try {
                let res = await fetch(ste_url,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    },
                    body: JSON.stringify(postParam),
                })
                let json_data = await res.json()

                this.$store.commit('updateQuoteData', json_data)

                this.dialog = false;
                if(json_data.errors) {
                    this.$store.commit('updateZohoID', {zoho_id: json_data.lead.ZohoID})
                    this.subscription_dialog = true;
                    return false;
                }

                if (json_data.success === true) {
                    // log if bot lead
                    if(this.tky){
                        client.track('anonymous', 'form.botd.opensource.botlead', null, {
                            zohoid: json_data.lead.ZohoID,
                            form: JSON.stringify(this.form),
                        })
                    }

                    let quotes = json_data.quotes;
                    if(this.state_id === 'NY') {
                        quotes = quotes.filter(function(item) {
                            return item.Carrier !== 'John Hancock';
                        });
                    }

                    // push lead to switchboard for OC DTC leads
                    if(this.site == 'OC') {
                        try {
                            await this.routeToSwitchboardOC()
                        } catch (error) {
                            console.error('raqform->checkQuote->error', error)
                        }
                    }

                    let quoteParams = {};
                    quoteParams['quotes'] = quotes;
                    quoteParams['form_data'] = postParam;
                    quoteParams['zoho'] = json_data.lead;
                    quoteParams['agentInfo'] = {...this.agent, site:this.site};
                    quoteParams['site'] = this.site;
                    quoteParams['leadType'] = this.leadType;
                    quoteParams['oc_redirect_url'] = this.$route.query.redirect_url;
                    quoteParams['product_type'] = product_type;

                    this.$store.commit('updateZohoID', {zoho_id: json_data.lead.ZohoID})
                    this.$store.commit('updateQuoteParams', quoteParams)

                    this.isConversion = (json_data.scores.emailMatch || json_data.scores.phoneMatch)

                    // only log conversions
                    // when either the phone or email
                    // for this lead is valid.
                    if(this.isConversion)
                    {
                        this.logEvent(product_type, 'RAQ_Complete', 'Created Lead')
                        this.logBingConversion(product_type, 'RAQ_Complete', 'Created Lead');
                        this.logFBConversion(product_type, 'RAQ_Complete', 'Created Lead');
                    }

                    const query_param = this.$route.query.q;
                    if(typeof query_param !== 'undefined') {
                        if(query_param === 'ab1') {
                            this.$router.push({name: 'TermCoverage', params: quoteParams})
                        } else if(query_param === 'ab2') {
                            this.$router.push({name: 'multiple-quotes', params: quoteParams})
                        }
                    } else {
                        // const routeName = (this.site === 'OC') ? 'Symm-quotes' : 'Results';
                        // this.$router.push({name: routeName, params: quoteParams, query: {p:""}});

                        if(this.site === 'OC') {
                            this.$router.push({name: 'Symm-quotes', params: quoteParams, query: {p:""}});
                        } else {
                            let noQuoteData = true

                            if(!json_data.quotes){
                                // console.log('no quotes')
                                noQuoteData = true
                            }

                            for (const q of json_data.quotes) {
                                // console.log('q', q,'Premium', q.Premium, 'isset?', this.isset(q.Premium))
                                if(this.isset(q.Premium)) {
                                // console.log('quotes', q)
                                    noQuoteData = false
                                }
                            }

                            const routeName = noQuoteData === true ? 'QuotesThankYou' : 'Results'

                            console.log('quotes',JSON.stringify(json_data.quotes))
                            console.log('noQuoteData',noQuoteData,'routeName',routeName)

                            this.$router.push({name: routeName, params: quoteParams, query: {p:""}})
                        }
                    }
                }
                else if(json_data.success === false) {
                    this.quote_error = true;
                    this.quote_error_message = 'Error!. Please try again later.';
                }
            } catch (error) {
                console.error('raqform->checkQuote->error', error)
            }

        },
        setPlanItems: function () {
            const real_age = this.age;
            if (real_age > 45 && real_age <= 50) {
                this.removePlanItems(['30 Years'])
            } else if (real_age > 50 && real_age <= 55) {
                this.removePlanItems(['30 Years', '25 Years'])
            } else if (real_age > 55 && real_age <= 60) {
                this.removePlanItems(['30 Years', '25 Years', '20 Years'])
            } else if (real_age > 60) {
                this.plan_items = [
                    {text: '10 Years', value: '10 Years'}
                ]
            }

            if (this.state_id != null && (this.state_id === 'FL' || this.state_id === 'ND')) {
                this.removePlanItems(['15 Years']);
            }
        },
        insuranceAge: function (original_dob) {
            if (original_dob === '' || original_dob === null) {
                return;
            }

            // Age calculation based off of the following logic/example:
            // The way carriers look at nearest age is DOB and 7 full months, it will roll to the next age.
            // Ex:
            // DOB is 1/28/2000
            // Todays Date is 07/28/2023
            // Insurance age: 23
            // On 07/29/2023 insurance age changes to 24

            let todaysDate = new Date() // todays date
            let bd = new Date(original_dob) // birthdate and birth year
            let calculatedAge = todaysDate.getFullYear() - bd.getFullYear();

            // decrement since birthday for this year hasn't occured yet
            if(todaysDate.getMonth() < bd.getMonth()){
                calculatedAge--
            }

            // birthdate and current year
            let bdCurYear = new Date(original_dob)
            bdCurYear.setFullYear(todaysDate.getFullYear())

            let monthDiff = this.getMonthsBetween(bdCurYear, todaysDate, true)

            if(monthDiff >= 7) {
                calculatedAge++
            }

            this.age = calculatedAge
            return calculatedAge
        },
        removePlanItems(terms) {
            terms.forEach(term => {
                this.plan_items.forEach((item, index) => {
                    if (term === item.value) {
                        this.plan_items.splice(index, 1);
                    }
                })
            })
        },
        formatCoverage() {
            if(this.coverage_amount === '$'){
                return
            }

            this.coverage_amount = this.stripNonNumbericChars(this.coverage_amount)
        },
        sumitForm(){
            // manually triggering form submit event
            // to help with amplitude tracking
            this.$refs.form.$el.requestSubmit()
        },
    },
    mounted() {
        this.create_time = new Date()
        this.coverage_items = this.setCoverageItems(this.coverage_limit);

        const inputElement = this.$refs.fld_zipCode.$el.querySelector('input');
        inputElement.maxLength = 5;
        this.plan_items = this.plan_lists.terms;

        let routePath = this.$route.path;
        routePath = routePath.split('/');
        if(routePath[1] === 'symmetry') {
            this.leadType = 'OC DTC Lead';
        }

        if(!this.otp_treatment && this.ts_treatment){
            /*global turnstile*/
            turnstile.ready(() => {
                this.ts_id = turnstile.render('#cf-turnstile-container', {
                    sitekey: process.env.VUE_APP_CF_TS_SITE_KEY,
                    callback: async (token) => {
                        let res = await fetch(this.api_url + 'process-ts', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*'
                            },
                            body: JSON.stringify({'cf-ts-token': token}),
                        })

                        const data = await res.json()
                        this.ts_validated = data.success ?? false
                    },
                    theme: 'light',
                    action: process.env.VUE_APP_CF_ACTION,
                })
            })
        }

        // populate with fields in the store if possible
        if(this.form.dob){
            this.dob = this.form.dob
        }

        if(this.form.zip_code){
            this.zipCode = this.form.zip_code
        }

        if(this.form.zip_code){
            this.zipCode = this.form.zip_code
        }

        if(this.form.coverage_amount) {
            this.coverage_amount = this.form.coverage_amount
        }

        if(this.form.coverage_plan) {
            this.coverage_plan = this.form.coverage_plan
        }

        if(this.form.gender) {
            this.gender = this.form.gender
        }

        if(this.form.tobacco_used) {
            this.tobacco_used = this.form.tobacco_used
        }

        if(this.form.first_name) {
            this.firstName = this.form.first_name
        }

        if(this.form.last_name) {
            this.lastName = this.form.last_name
        }

        if(this.form.email) {
            this.email = this.form.email
        }

        if(this.form.phone_number) {
            this.phoneNumber = this.form.phone_number
        }

        if(this.form.Spanish_Speaking) {
            this.Spanish_Speaking = this.form.Spanish_Speaking
        }

        console.log('treatments', this.treatments)
    },
}
</script>

<style scoped>
    .q-error-text{
        color: red;
        padding-left: 16px;
        padding-right: 16px;
        overflow-wrap: break-word;
        hyphens: auto;
        line-height: 12px;
        margin-top: 4px;
        font-size: 12px !important;
        font-family: "Montserrat", Sans-serif !important;
    }
    .error-outline{
        border: 2px solid red;
        border-radius: 5px;
        padding: 5px
    }
    .v-messages {
        min-height: 1px;
    }
    .get-a-quote-container {
        font-family: montserrat, Sans-serif;
    }

    .get-a-quote-title {
        font-family: "Montserrat", Sans-serif !important;
        font-weight: 300;
        text-align: left;
        line-height: 1em;
        color: #002421;
    }

    .estimate-msg {
        font-size: 3rem;
        color: #46C3B2;
        font-family: freight-display-pro, Sans-serif !important;
        line-height: 1.3em;
        font-weight: 900;
    }

    .med-thin-text {
        margin-top: 3%;
        font-size: calc(18px + (35 - 22) * ((100vw - 400px) / (2000 - 400))) !important;
        font-family: "Montserrat", Sans-serif !important;
        font-weight: 300;
        line-height: 2.5rem;
        color: #000000;
    }

    .breaking-border {
        background: #DAF3F0;
        width: 51.58px;
        height: 8.38px;
        margin-top: 2%;
    }

    .coverage-title-container {
        font-size: 0.9rem;
        line-height: 1.2rem;
        color: white;
        background: #46C3B2;
        font-family: montserrat, Sans-serif
    }

    .input-control-text {
        font-family: "Montserrat", Sans-serif !important;
    }

    .input-control-text-small {
        font-family: "Montserrat", Sans-serif !important;
        font-size: smaller;
    }
    .price-teaser-period {
        font-size: 0.8rem;
    }

    .dialog-text {
        font-family:Montserrat; font-weight: normal; font-size: 0.8rem;  color: black;
    }

    .theme--light.v-btn.v-btn--has-b {
        background-color: #005951;
        color: white;
    }

    @media only screen and (max-width: 768px) {
        .estimate-msg {
            font-family: freight-display-pro, Sans-serif !important;
            font-size: 2rem;
            line-height: 1.2em;
            margin-bottom:0;

        }

        .get-a-quote-block {
            font-size: 0.8rem;
            white-space: pre-wrap;
            word-break: keep-all;
        }

        .price-teaser {
            margin-top:14%;
        }

        .price-teaser-period {
            margin-top:6%;
            font-size: 0.8rem;
            white-space: nowrap;
            word-break: keep-all;
        }

        input::-webkit-input-placeholder{
            color:red;
        }
        input:-moz-placeholder {
            color:red;
        }
    }
</style>
