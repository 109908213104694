import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    agent: {
        'agentName': null,
        'agentPreferredName': null,
        'agentFirstName': null,
        'agentLastName': null,
        'agencyName': null,
        'agentPhone': null,
        'agentEmail': null,
        'agentPayrollID': null,
        'AgentID': null,
        'agentAddress': null,
        'agentCity': null,
        'agentState': null,
        'agentZip': null,
        'agentCode': null,
        'agentLicenses': null,
        'amam_writingNumbers': null,
        'Signature': null,
        'HasOCDTCSubscription': null,
        'OCDTCAvatar': null,
        'AffiliateCode': null,
        'C': null,
        'SB': null,
    },
    form: {
        dob: null,
        dob_formatted: null,
        coverage_amount: null,
        coverage_plan: null,
        gender: null,
        tobacco_used: null,
        zip_code: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        spanish_speaking: null,
        state_id: null,
        city: null,
        product_type: null,
        age: null,
        lead_type: null,
        county_name: null,
    },
    site: null,
    zoho_id: null,
    quote_data: {},
    fetching_quote: false,
    entering_e_app: false,
    forward_cesc: false,
    redirect_url: null,
    treatments: {},
    TRCKERYGAMBLESWITLiesHppnessAdEDstnytse: false,
    showChat: false,
    selected_product: null,
    quote_params: null
  },
  mutations: {
    updateAgent(state, payload){
        for (let k of Object.keys(payload)) {
            // console.log('key', k)
            if(k in state.agent){
                state.agent[k] = payload[k]
            }
        }
    },
    updateForm(state, payload){
        for (let k of Object.keys(payload)) {
            // console.log('key', k)
            if(k in state.form){
                state.form[k] = payload[k]
            }
        }
    },
    updateSite(state, payload){
        state.site = payload.site
    },
    updateZohoID(state, payload){
        state.zoho_id = payload.zoho_id
    },
    updateQuoteData(state, payload) {
        state.quote_data = payload
    },
    updateFetchingQuote(state, status){
        state.fetching_quote = status
    },
    updateEnteringEApp(state, status){
        state.entering_e_app = status
    },
    updateForwardCesc(state, status){
        state.forward_cesc = status
    },
    updateRedirectUrl(state, status){
        state.redirect_url = status
    },
    updateTreatments(state, treatments){
        for (const treatment of treatments) {
            const {name, value, configs} = treatment
            state.treatments[name] = {
                value,
                configs
            }
        }
    },
    updateFlag(state, payload){
        state.TRCKERYGAMBLESWITLiesHppnessAdEDstnytse = payload
    },
    updateChat(state, status) {
        state.showChat = status
    },
    updateSelectedProduct(state, product) {
        state.selected_product = product
    },
    updateQuoteParams(state, payload){
        state.quote_params = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
