<template>
    <v-container>
        <v-container class="px-10">
        <v-row class="insurance-option">
            <v-col style="font-size: 2rem; font-weight: 600; ">
                Thank you!
                Our team is currently processing your request.
            </v-col>
            <v-col class="pa-4">
                <v-btn depressed fab color="#015851"  ><strong  style="color:white;font-size: 1.6rem;">1</strong></v-btn><br /><br/>
                <p class="steps-msg">A licensed insurance agent will reach out to you to schedule your consultation in the next 24 hours via contact information
                    you provided.</p>
            </v-col>
            <v-col class="pa-4">
                <v-btn depressed fab color="#015851"  ><strong  style="color:white;font-size: 1.6rem;">2</strong></v-btn><br /><br/>
                <p class="steps-msg">You will have option to meet with your advisor in person, over the phone or through video conference.</p>
            </v-col>
            <v-col class="pa-4">
                <v-btn depressed fab color="#015851"  ><strong  style="color:white;font-size: 1.6rem;">3</strong></v-btn><br /><br/>
                <p class="steps-msg">Once you find your perfect plan, your advisor will help you apply for coverage with one of our trusted insurance companies.</p>
            </v-col>
        </v-row>
        <v-row class="next-msg">
            <v-col class="col-md-3 next-msg-first" >
                Here's what happens next.
            </v-col>
            <v-col class="col-md-9 mt-15 next-msg-second" >
                Have questions? Contact Us at (888) 784-5489 or <span style="color:#46C3B2;text-decoration: underline;cursor: pointer;">help@quility.com</span>.
            </v-col>
        </v-row>

        <v-row  style="margin-top: 10%;">
            <v-col class="col-md-6  text-center justify-center">
                <v-img
                        alt="Quility Logo"
                        :src="require('../assets/Woman-In-Yellow_working.png')"
                        transition="scale-transition"
                        width="400"
                        class="pl-md-15 mt-md-10"
                />
            </v-col>
            <v-col>
                <v-row>
                    <v-col style="font-family: freight-display-pro, Sans-serif; font-size: 2rem; color: black ;font-weight: 900;">
                        Quility Resources
                    </v-col>
                </v-row>
                <v-row class="mt-5">
                    <v-col class="col-md-8">
                        <div style="font-weight: 600;">Help Center</div>
                        <div style="font-family: Montserrat">
                            Get the answers to your life insurance questions in the Quility Help Center.
                        </div>
                        <div style="font-family: Montserrat;color: #46C3B2;">Read More&nbsp;&rarr;
                        </div>
                    </v-col>
                    <v-col class="col-md-8">
                        <div style="font-weight: 600;">Life insurance 101</div>
                        <div style="font-family: Montserrat">
                            Learn more about your policy options on our life insurance guide.
                        </div>
                        <div style="font-family: Montserrat;color: #46C3B2;">Read More&nbsp;&rarr;
                        </div>
                    </v-col>
                    <v-col class="col-md-8">
                        <div style="font-weight: 600;">Featured Articles</div>
                        <div style="font-family: Montserrat">
                            Catch up on our most recent articles on life insurance solutions.
                        </div>
                        <div style="font-family: Montserrat;color: #46C3B2;">Read More&nbsp;&rarr;
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        </v-container>
    </v-container>
</template>

<script>
export default {
    name: "Thankyou"
}
</script>

<style scoped>
.insurance-option {
    font-family: montserrat, Sans-serif;
}
.next-msg {
    font-family: montserrat, Sans-serif;font-weight: 300;
}
.next-msg-first {
    font-size: 1.6rem;
    color:black;
}

.next-msg-second {
    font-size: 1.7rem;
    font-weight: 300;
    color: black;
}

.steps-msg {
    font-weight: 300;
    color:black;
    font-size: 1.2rem;
}
</style>
