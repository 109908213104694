<template>
    <div id="footer-font">
        <v-footer style="background: #F8F8F8;">
            <v-container>
                <v-row>
                    <v-col cols="12">
                    <v-card flat style="background: #F8F8F8;">
                        <v-card-text>
                            <div style="font-family: Montserrat; opacity: 0.66; font-weight: 300;">
                                <p v-if="isQuotePage" class="mt-4" style="padding:2rem 0 0 0; white-space: pre-wrap;word-break: keep-all;">
                                    *The rates shown above are not final. All final life insurance approvals and actual quotes are subject to personal health history and carrier-specific underwriting guidelines. Your actual rates and
                                    quotes may vary, and the underwriting process may determine that your actual premium quotes are higher or lower than the rates shown above. You will have a chance
                                    to review and accept the terms and conditions of any insurance policy you may apply for before you are charged. Not all applicants will qualify.
                                </p>
                                <p class="mt-4">
                                    By providing your information and/or utilizing the Service
                                    (as defined in the <a class="top-footer-anchor" target="_blank" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a> and <a class="top-footer-anchor" target="_blank" href="https://quility.com/privacy-policy" >Privacy Policy</a>) and
                                    this Website (as defined in the <a class="top-footer-anchor" target="_blank" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a> and <a class="top-footer-anchor" target="_blank" href="https://quility.com/privacy-policy" >Privacy Policy</a>),
                                    you agree to this Website’s <a class="top-footer-anchor" target="_blank" href="https://quility.com/privacy-policy" >Privacy Policy</a> and <a class="top-footer-anchor" target="_blank" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a> and you
                                    consent to be contacted (by emails, telephone calls, text messages, SMS or other
                                    forms of electronic communication such as a mobile application’s push notifications to the
                                    contact information you provide) by Quility Insurance Holdings LLC, any one or more of its
                                    direct or indirect subsidiaries, sister companies, or parent companies, or any of their employees or
                                    independent contractors or other affiliates ("Quility"), regarding the Service (as defined in the <a class="top-footer-anchor" target="_blank" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a> and <a class="top-footer-anchor" target="_blank" href="https://quility.com/privacy-policy" >Privacy Policy</a>)
                                    generally as well as regarding news, special offers and general information about other goods, services and events
                                    which we offer that are similar to those that you have already purchased or inquired about.
                                </p>

                                <p class="mt-4">
                                    John Hancock insurance products issued by: John Hancock Life Insurance Company (U.S.A.), Boston, MA 02116.
                                </p>
                                <p class="mt-4">
                                    John Hancock Policy Form Series: ICC21 21LFTERMHE, 21LFTERMHE.
                                    <a class="top-footer-anchor" href="https://quility.com/vitality-benefits-disclosure/" target="_blank">Click here</a> for more important John Hancock disclosure.
                                </p>

                                <p class="mt-4">
                                    "Quility Level Term" is a brand name used by Quility, for level term life insurance policies issued by The
                                    Savings Bank Mutual Life Insurance Company of Massachusetts ("SBLI") on policy form series 21-P-SIT.
                                    Centrian Life Insurance is a registred trade name and Connecticut d/b/a of SBLI.
                                    Policies sold in the state of Connecticut will be issued under Centrian Life Insurance ("Centrian").
                                </p>

                                <p class="mt-4">
                                    MLI083021349-1
                                </p>
                                <p class="mt-5">
                                    Copyright © 2020 Quility Holdings, LLC. All Rights Reserved.
                                </p>
                            </div>
                        </v-card-text>
                    </v-card>
                    </v-col>
                </v-row>

            </v-container>
        </v-footer>
        <v-footer class="pt-8" style="background: #005851;">
            <v-container v-if="isMobile" style="color: white; margin-left: 16px;">
                <v-row>
                    <v-col>
                        <v-img
                                alt="Quility Logo"
                                :src="require('../assets/Quility_logo_desktop.svg')"
                                contain
                                max-height="30"
                                position="left"
                        />
                    </v-col>
                </v-row>
                <v-row><v-col><span class="font-weight-bold" style="color: #46c3b2;font-size: clamp(17px, 2vw, 20px);">Work With Us</span></v-col></v-row>
                <v-row class="mt-n4"><v-col><a href="https://quilityb2b.com/">Quility B2B</a></v-col></v-row>
                <v-row class="mt-n4"><v-col><a href="https://quility.com/press/">Press</a></v-col></v-row>
                <v-row class="mt-n4"><v-col><a href="https://quility.com/ripple/">The Ripple Model™</a></v-col></v-row>
                <v-row class="mt-n4"><v-col><a href="https://quility.com/join-our-team/agent-careers/">Agent Careers</a></v-col></v-row>

                <v-row class="mt-4"><v-col><span class="font-weight-bold" style="color: #46c3b2;font-size: clamp(17px, 2vw, 20px);">Company</span></v-col></v-row>
                <v-row class="mt-n4"><v-col><a href="https://quility.com/about/">Who We Are</a></v-col></v-row>
                <v-row class="mt-n4"><v-col><a href="https://quility.com/about-quigley/">Meet Quigley</a></v-col></v-row>
                <v-row class="mt-n4"><v-col><a href="http://quility.com/join-our-team/corporate-careers/">Corporate Careers</a></v-col></v-row>

                <v-row class="mt-4"><v-col><span class="font-weight-bold" style="color: #46c3b2;font-size: clamp(17px, 2vw, 20px);">Contact</span></v-col></v-row>
                <!-- <v-row class="mt-n4"><v-col><a href="">Live Chat</a></v-col></v-row> -->
                <v-row class="mt-n4"><v-col><a href="https://quility.com/contact/">Contact Us</a></v-col></v-row>
                <v-row class="mt-n4"><v-col><a href="tel:8887845489">(888) 784-5489</a></v-col></v-row>
                <v-row class="mt-n4"><v-col><a href="https://goo.gl/maps/oQEZ14qM2xTxvLa18">204 Whitson Ave. <br/>Swannanoa, NC</a></v-col></v-row>
                <v-row>
                    <v-col>
                        <a href="https://quility.com/about/">
                            <div style="border: 1px solid #46C3B2; text-align: left; padding: 10px; height: 44.5px; width: 285px;">Made With <v-icon>fas fa-heart t-water-green</v-icon> in CA, NC and NV.</div>
                        </a>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                            <a class="mr-5" href="https://www.facebook.com/QuilityInsurance/"><v-icon color="white">fab fa-facebook-f</v-icon></a>
                            <a class="mr-5" href="https://www.instagram.com/QuilityInsurance/"><v-icon color="white">fab fa-instagram</v-icon></a>
                            <a class="mr-5" href="https://twitter.com/QuilityI"><v-icon color="white">fab fa-twitter</v-icon></a>
                            <a class="mr-5" href="https://www.linkedin.com/company/quility-insurance/"><v-icon color="white">fab fa-linkedin-in</v-icon></a>
                            <a class="mr-5" href="https://quilityinsurance.medium.com/"><v-icon color="white">fab fa-medium-m</v-icon></a>
                            <a class="mr-5" href="https://www.youtube.com/channel/UCfkmiWEzNTEVU2422FMvIow"><v-icon color="white">fab fa-youtube</v-icon></a>
                            <a class="mr-5" href="https://open.spotify.com/user/31fzmyk6thysqwptp34kgwlqwwq4"><v-icon color="white">fab fa-spotify</v-icon></a>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col style="font-size: 13px;">
                        <a class="mx-1" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a>|<a class="mx-1" href="https://quility.com/privacy-policy/">Privacy Policy</a>|<a class="mx-1" href="https://quility.com/privacy-policy/#license">License</a>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <span class="text-center bottom-text">“Quility” is a brand name used by the Quility family of companies. All insurance offers, solicitations, and recommendations made via this website are being made by Quility’s licensed affiliated insurance producers and Symmetry Financial Group, LLC (d/b/a Symmetry Insurance Services in California), Quility Insurance Services LLC, Quility-Asurea Agency Insurance Services LLC (d/b/a QuotePath Insurance Marketing and Mortgage Protection Insurance Services in California), or Quility Affinity Insurance Services LLC (d/b/a Asurea Wholesale Insurance Services LLC in California). No offers, solicitations or recommendations are being made via this website in any state where one of those named Quility licensees does not have a license. Please see our License Page for a list of our license numbers in each state.</span>
                    </v-col>
                </v-row>
            </v-container>
            <v-container v-else style="color: white;">
                <v-row>
                    <v-col>
                        <a href="https://quility.com/">
                            <v-img
                                    alt="Quility Logo"
                                    :src="require('../assets/Quility_logo_desktop.svg')"
                                    contain
                                    max-height="50"
                                    position="left"
                            />
                        </a>
                    </v-col>
                    <v-col><span style="color: #46c3b2; font-size: clamp(17px, 2vw, 20px);font: normal normal bold 20px/30px Montserrat;">Work With Us</span></v-col>
                    <v-col><span style="color: #46c3b2; font-size: clamp(17px, 2vw, 20px);font: normal normal bold 20px/30px Montserrat;">Company</span></v-col>
                    <v-col><span style="color: #46c3b2; font-size: clamp(17px, 2vw, 20px);font: normal normal bold 20px/30px Montserrat;">Contact</span></v-col>
                </v-row>
                <v-row class="mt-n9">
                    <v-col></v-col>
                    <v-col><a href="https://quilityb2b.com/">Quility B2B</a></v-col>
                    <v-col><a href="https://quility.com/about/">Who We Are</a></v-col>
                    <v-col><a href="https://quility.com/contact/">Contact Us</a></v-col>
                </v-row>
                <v-row class="mt-n4">
                    <v-col></v-col>
                    <v-col><a href="https://quility.com/press/">Press</a></v-col>
                    <v-col><a href="https://quility.com/about-quigley/">Meet Quigley</a></v-col>
                    <v-col><a href="tel:8887845489">(888) 784-5489</a></v-col>
                </v-row>
                <v-row class="mt-n4">
                    <v-col></v-col>
                    <v-col><a href="https://quility.com/ripple/">The Ripple Model™</a></v-col>
                    <v-col><a href="http://quility.com/join-our-team/corporate-careers/">Corporate Careers</a></v-col>
                    <v-col><a href="https://goo.gl/maps/oQEZ14qM2xTxvLa18">204 Whitson Ave. <br/>Swannanoa, NC</a></v-col>
                </v-row>
                <v-row class="mt-n10">
                    <v-col></v-col>
                    <v-col><a href="https://quility.com/ripple/">Agent Careers</a></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                </v-row>
                <v-row class="mt-15">
                    <v-col cols="3">
                        <a href="https://quility.com/about/">
                            <div style="border: 1px solid #46C3B2; text-align: left; padding: 10px; height: 44.5px; width: 285px;">Made With <v-icon>fas fa-heart t-water-green</v-icon> in CA, NC and NV.</div>
                        </a>
                    </v-col>
                    <v-col cols="5">
                        <div class="text-center">
                            <a class="ml-4" href="https://www.facebook.com/QuilityInsurance/"><v-icon color="white">fab fa-facebook-f</v-icon></a>
                            <a class="ml-4" href="https://www.instagram.com/QuilityInsurance/"><v-icon color="white">fab fa-instagram</v-icon></a>
                            <a class="ml-4" href="https://twitter.com/QuilityI"><v-icon color="white">fab fa-twitter</v-icon></a>
                            <a class="ml-4" href="https://www.linkedin.com/company/quility-insurance/"><v-icon color="white">fab fa-linkedin-in</v-icon></a>
                            <a class="ml-4" href="https://quilityinsurance.medium.com/"><v-icon color="white">fab fa-medium-m</v-icon></a>
                            <a class="ml-4" href="https://www.youtube.com/channel/UCfkmiWEzNTEVU2422FMvIow"><v-icon color="white">fab fa-youtube</v-icon></a>
                            <a class="ml-4" href="https://open.spotify.com/user/31fzmyk6thysqwptp34kgwlqwwq4"><v-icon color="white">fab fa-spotify</v-icon></a>
                        </div>
                    </v-col>
                    <v-col cols="4">
                        <div class="text-right">
                            <a class="mx-1" href="https://quility.com/terms-and-conditions/">Terms & Conditions</a>|<a class="mx-1" href="https://quility.com/privacy-policy/">Privacy Policy</a>|<a class="mx-1" href="https://quility.com/privacy-policy/#license">License</a>
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mt-8">
                    <v-col class="pr-0">
                        <div class="text-left bottom-text">“Quility” is a brand name used by the Quility family of companies. All insurance offers, solicitations, and recommendations made via this website are being made by Quility’s licensed affiliated insurance producers and Symmetry Financial Group, LLC (d/b/a Symmetry Insurance Services in California), Quility Insurance Services LLC, Quility-Asurea Agency Insurance Services LLC (d/b/a QuotePath Insurance Marketing and Mortgage Protection Insurance Services in California), or Quility Affinity Insurance Services LLC (d/b/a Asurea Wholesale Insurance Services LLC in California). No offers, solicitations or recommendations are being made via this website in any state where one of those named Quility licensees does not have a license. Please see our License Page for a list of our license numbers in each state.</div>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </div>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "Footer",
    mixins: [quilityMixin],
}
</script>

<style scoped>
    a:link, a:visited, a:hover, a:active {
    text-decoration: none;
    color: white;
    }

    #footer-font{
        font-family: Montserrat;
    }

    .bottom-text {
        text-align: left;
        font: normal normal 300 15px/23px Montserrat;
        letter-spacing: 0.07px;
        color: #FFFFFF;
    }

    .top-footer-anchor {
        text-decoration: underline !important;
        font: italic normal 300 12px/20px Montserrat;
        letter-spacing: 0px;
        color: #000000 !important;
    }
</style>
