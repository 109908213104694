<template>
    <v-card
        style="font-family: Montserrat; border: 1px solid #0083EB; border-radius: 10px !important;"
        class="elevation-0 mt-8"
        :height="cardheight"
        width="344"
    >
        <div style="background: #0083EB 0% 0% no-repeat padding-box; border: 1px solid #0083EB; border-radius: 6px 6px 0px 0px;">
            <p style="text-align: center; font: normal normal normal 11px/34px Montserrat;letter-spacing: 0px;color: #FFFFFF;text-transform: uppercase; margin: 0px;">QUIGLEY'S PICK</p>
        </div>
        <!-- <v-img :src="sbli_img" width="200px" class="ml-7 mt-8"></v-img> -->
        <v-img v-if="!isMobile" :src="sbli_img" width="150px" style="margin: 20px auto 0px auto; border-radius: 0px !important;"></v-img>
        <v-img v-else :src="sbli_img" width="150px" class="mx-auto mt-8" style=" border-radius: 0px !important;"></v-img>
        <v-card-text>
            <v-row class="mt-3 ml-1 amount">
                <v-col v-if="freq_treatment" cols="7">
                    <span style="color: #0083EB; font-size: large;"><sup>$</sup></span>
                    <span class="premium">{{primaryPremium}} </span>
                    <span class="main-frequency">{{ main_frequency }}<sup><span style="color:#0083EB">*</span></sup></span>
                </v-col>
                <v-col v-else cols="12">
                    <span style="color: #0083EB; font-size: large;"><sup>$</sup></span>
                    <span class="premium">{{primaryPremium}} </span>
                    <span class="main-frequency">{{ main_frequency }}<sup><span style="color:#0083EB">*</span></sup></span>
                </v-col>

                <v-col v-if="freq_treatment" cols="5">
                    <sup style="color: #666666; font-size: xx-small;">$</sup>
                    <span class="secondary-premium" style="color: #666666">{{ secondaryPremium }}</span>
                    <span class="secondary-premium"> {{ secondary_frequency }}<sup><span style="color:#0083EB">*</span></sup></span>
                </v-col>
            </v-row>
            <v-card-actions
                @click="show = !show"
            >
                <v-btn
                text
                class="text-subtitle-2"
                >
                    <span style="text-align: left; font: normal normal bold 17px/35px Montserrat; letter-spacing: 0px; color: #1D4588; opacity: 1;">
                        Quility Level Term Benefits
                    </span>
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                color="#0083EB"
                icon
                >
                <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-card-actions>

            <v-expand-transition>
                <div v-show="show">

                <v-card-text>
                    <v-list flat>
                        <v-list-item
                            class="mt-n5"
                            v-for="(msg, i) in sbli_card_info.messages"
                            :key="i"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon color="q-primary">mdi-circle-small</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="text-caption text-wrap" style="font-family: Montserrat !important" v-html="msg"></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                </div>
            </v-expand-transition>

            <v-btn
                color="#0083EB"
                depressed
                class="get-started px-3"
                block
                @click="launchEapp"
                :disabled="fetchingQuote"
                :style="show ? null : 'margin-top: 34px;'"
            >
                Continue
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import quilityMixin from "@/mixins/quilityMixin";

export default {
    name: "QLTQuoteCard",
    mixins: [quilityMixin],
    data: () => ({
        sbli_img: null,
        show: false
    }),
    mounted(){
        this.sbli_img = this.getImgUrl(this.sbli_card_info.logo)
        this.show = !this.isMobile
    },
    computed: {
        sbliData(){
            return this.quoteData.quotes.find(q => q.Carrier === "SBLI")
        },
        monthlyPremium(){
            return this.formatNumber(2,2,this.sbliData.Premium)
        },
        cardheight() {
            return this.show ? '480' : '323'
        },
        dailyPremium(){
            return this.formatNumber(2,2, (this.sbliData.Premium / 30))
        },
        primaryPremium(){
            return this.freq_treatment ? this.dailyPremium : this.monthlyPremium
        },
        secondaryPremium(){
            return this.freq_treatment ? this.monthlyPremium : this.dailyPremium
        },
    },
    methods: {
        async launchEapp(){
            // qlt cta split event
            let client = await this.$split_client.latest
            client.track('anonymous', 'form.click.cta.qlt')

            if(this.otp_treatment === true){
                this.$store.commit('updateSelectedProduct', 'QLT')
                this.$router.push({name: 'Verify'});
            } else {

                // only tracking for corp dtc at the moment
                if(this.site === 'QUILITY'){
                    let res = this.$amplitude_client.track('QLT_e-app', this.amp_event_data)
                    console.info('amplitude track: ', res)
                }

                this.process_sbli()
            }
        },
        async process_sbli() {
            let client = await this.$split_client.latest

            this.$store.commit('updateEnteringEApp', true)
            this.eApp_dialog = true
            const parameters = this.$route.params

            const lead_info = {
                'premium': this.sbliData.Premium,
                'zoho_id': this.zohoid,
                'product': 'Quility Level Term',
                'disposition': 'RAQ Apply Now'
            }

            try {
                await this.updateLeadProductPremium(parameters.form_data, lead_info, 'sbli')
                const phone = this.form.phone_number
                let phone_clean = phone.replace('(', '')
                phone_clean = phone_clean.replace(')', '')

                const d = new Date(this.form.dob)
                let dob_m = '' + (d.getMonth() + 1)
                let dob_d = '' + d.getDate()
                let dob_y = d.getFullYear()

                if(dob_m.length < 2){
                    dob_m = '0' + dob_m
                }

                if(dob_d.length < 2){
                    dob_d = '0' + dob_d
                }

                const postParam = {
                    email: this.form.email,
                    leadReferenceId: this.zohoid,
                    phone: phone_clean,
                    zip: this.form.zip_code,
                    firstname: this.form.first_name,
                    lastname: this.form.last_name,
                    dob: `${dob_y}-${dob_m}-${dob_d}`,
                    gender: this.form.gender,
                    state: this.form.state_id,
                    coverage: this.form.coverage_amount,
                    term: this.form.coverage_plan,
                    smoker: this.form.tobacco_used,
                    arcId: this.sbliData.arcId,
                    agentCode: (this.site === 'OC') ? this.agent.agentCode : process.env.VUE_APP_QLTCORPAGENT,
                    AffiliateCode: this.agent.AffiliateCode,
                }

                let ste_url = process.env.VUE_APP_PROXY + 'get-sbli-url';

                try {
                    let res = await fetch(ste_url,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*'
                        },
                        body: JSON.stringify(postParam),
                    })
                    let json_data = await res.json()

                    if (json_data.success == false) {
                        lead_info['disposition'] = 'Quote Error';
                        await this.updateLeadProductPremium(parameters, lead_info, 'sbli')
                        this.eApp_dialog = false
                        return false
                    }

                    client.track('anonymous', 'form.eapp.start')
                    if(this.site === 'OC'){
                        client.track('anonymous', 'form.eapp.ocdtc.qlt.start')
                    } else {
                        client.track('anonymous', 'form.eapp.qlt.start')
                    }
                    // kill split instance
                    await this.$split_client.delete()

                    window.location.href = json_data.data.redirectUrl
                } catch (error) {
                    // kill split instance
                    await this.$split_client.delete()

                    console.error('multiplequotes->process_sbli->error', error)
                }
            } catch (error) {
                console.error('multiplequotes->process_sbli->error', error)
                this.error_dialogue = true
                this.eApp_dialog = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
$text--secondary: #9B9B9B;

.amount {
    font-weight: 800;
}

.get-started {
    color: #fff !important;
    font-family: Montserrat, SemiBold;
    font-weight: 600;
    font-size: 0.8rem;

    @media only screen and (max-width: 486px) {
        & {
            font-size: 0.7rem !important;
            img {
                width: 12px;
            }
        }
    }
}

.premium {
    text-align: left;
    font: normal normal 800 20px/34px Montserrat;
    letter-spacing: 0px;
    color: #0083EB;
}

.main-frequency {
    text-align: left;
    font: normal normal bold 15px/34px Montserrat;
    letter-spacing: 0px;
    color: #0083EB;
}

.secondary-premium {
    text-align: left;
    font: normal normal normal 12px/34px Montserrat;
    letter-spacing: 0px;
    color: #666666;
}
</style>